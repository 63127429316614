<template>
    <span>
        <spinner-overlay v-model="overlay" :persistent="true"></spinner-overlay>
        <v-app-bar dense flat height="45">
            <v-app-bar-nav-icon @click="(mobileDrawer == false) ? mobileDrawer = true: mobileDrawer = false" 
                class="d-flex d-md-none"></v-app-bar-nav-icon>
            <div class="logo">
            </div>
            <v-tabs 
                color="var(--v-primary-base)"
                align-tabs="end"
                hide-slider
                class="tabs d-none d-md-flex"
            >
                <v-spacer></v-spacer>

                <template v-for="(menuItem, index) in menuItems"> 
                    <v-tab 
                        :class="(menuItem.routeName) ? {'highlight':getOnThisPage(menuItem.routeName)} : ''"
                        @click="(menuItem.function) ? menuItem.function(menuItem.path) : null">{{menuItem.name}}</v-tab>
                </template>
            </v-tabs>
            <v-menu
                v-if="user.username"
                bottom
                left
                offset-y
                :close-on-content-click="false"
                >
                <template v-slot:activator="{ props }">
                    <v-icon
                    v-bind="props"
                    :class="{'highlight':onSubmenuPage, 'user-menu-icon':true}">
                    mdi-account
                    </v-icon>
                </template>
                <v-list
                    nav
                    density="compact"
                >
                 
                    <v-list-item>
                        <v-list-item-title @click="goToPage('/user/settings')">Settings</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-title style="font-size:12px;" @click="logout()">Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
        <v-navigation-drawer
            v-model="mobileDrawer"
            absolute
            temporary
        >
            <v-list
            nav
            density="compact"
            >
               
                <v-list-item v-for="(menuItem, index) in menuItems"> 
                    <v-list-item-title
                        :class="(menuItem.routeName) ? {'highlight':getOnThisPage(menuItem.routeName)} : ''"
                        @click="(menuItem.function) ? menuItem.function(menuItem.path) : null">{{menuItem.name}}</v-list-item-title>
                </v-list-item>
              
            </v-list>
        </v-navigation-drawer>
    </span>
</template>
  
<script>
import { Hub, Auth } from 'aws-amplify';
import {nextTick} from 'vue'; 
import SpinnerOverlay from './SpinnerOverlay.vue';

  export default {
    components: {
      SpinnerOverlay
    },
    async created () { 
        Hub.listen('auth', (data) => {
            switch (data.payload.event) {
                case 'signIn':
                    this.user = data.payload.data; 
                    break;
                case 'signUp':
                    console.log('user signed up');
                    break;
                case 'signOut':
                    console.log('user signed out');
                    this.user = {}; 
                    break;
                case 'signIn_failure':
                    console.log('user sign in failed', data);
                    break;
                case 'configured':
                    console.log('the Auth module is configured');
            }
        });
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                this.user = user; 
            }
        }  
        catch(err) {
            this.user = {}; 
        }
    },
    props: {
      tiny: Boolean
    },
    destroyed() {
  
    },
    mounted() {
    
    },  
    methods: {
      async logout() {
        this.overlay = true; 
        try {
            await Auth.signOut();
            this.user = {};             
        } catch (error) {
            console.log('error signing out: ', error);
        }
        this.overlay = false; 
      }, 
      goToPage(path) {
        fbq('trackCustom', 'NavBarItemClicked', {path:path});
        this.$router.push(path); 
      },
      getOnThisPage(pageName) {
        if (this.currentRoute.name == pageName) {
            return true; 
        } else {
            return false; 
        }
      },
      checkAuthenticateBeforeRouting() {
        fbq('trackCustom', 'NavBarItemClicked', {path:'/draw'});
        if (this.user.username) {
            //already authenticated
            if (this.currentRoute.name == 'routeDrawTool' || this.currentRoute.name == 'editPaddle') {
                //refresh the page in this case because you're already on it and mounted hook won't
                //be called again. for when you are on the edit screen but then clicked to go to the route draw tool.
                window.location.href = '/draw'; 
  
            } else {
                this.$router.push('/draw'); 
            }
        } else {
            localStorage.setItem('routeAfterAuth', '/draw');
            this.$router.push('/auth'); 
        }
      }
    },
    data() {
      return {
        user: {}, 
        overlay: false,
        mobileDrawer: false
      }
    },
    computed: {
       menuItems() {
            let menuItems = [
                { path: '/about', name: 'About', routeName: 'marketing', function:this.goToPage},
                { path: '/map', name: 'Map', routeName: 'mainMap', function:this.goToPage }, 
                { path: '/draw', name: 'Draw', routeName: 'routeDrawTool', function:this.checkAuthenticateBeforeRouting}
            ]

            if (!this.user.username) {
                menuItems.push({
                    path: '/auth', name: 'Login', routeName: '', function:this.goToPage
                }); 
            }
            if (this.user.username) {
                menuItems.push({
                    path: '/myroutes', name: 'My Routes', routeName: 'myPaddlesView', function: this.goToPage
                }); 
            }
         return menuItems; 
       }, 
       currentRoute() {
         return this.$router.currentRoute.value;  
       }, 
       onSubmenuPage() {
        return false; 
        //  return this.currentRoute.name === 'myPaddlesView'; 
       }
    },
  }
</script>
  
<style lang="scss" scoped>
    :deep(.v-tabs) {
        .v-btn .v-btn__overlay {
        display:none; 
        }
        .theme--light.v-btn:before{
        display:none; 
        }
        .v-ripple__container {
        opacity: 0 !important;
        }
    }
    .user-menu-icon {
        cursor:pointer;
        margin-right: 10px;
        margin-left: auto;
        display:block; 
    }
    .v-toolbar {
        background-color: #fff!important;
        background-color:rgba(255, 255, 255,1)!important;
        z-index:2!important; 
    }
    .logo {
        background-image: url('../assets/logo_white_transparent.png');
        background-size: contain;
        position:relative;
        z-index:34; 
        height: 40px;
        top: 0px;
        left: 5px;
        width: 300px;
    }
    .tabs {
        width:100%; 
        margin-right:20px; 
        :deep(.v-slide-group__content) {
            align-items:center; 
        }
    }
    .theme--light.v-icon:focus:after {
        opacity: 0; 
    }
    .v-list-item {
        min-height:0px; 
        cursor:pointer; 
        padding-top:10px; 
        padding-bottom:10px; 
    }
    .v-list-item--nav .v-list-item-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1.25px; 
    }
    .v-list-item:hover{
        background: rgba(0,0,0,0.02);
    }
    .highlight {
        :deep(.v-btn__content), &.v-list-item-title {
           color:rgb(var(--v-theme-primary)); 
        }
    }
    :deep(.v-toolbar__content > .v-btn:first-child) {
        margin-inline-start:0px; 
    }

</style>
  
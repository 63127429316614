<template>
    <v-card class="main">
        <v-container fluid class="feature-image-section-container">
            <v-row>
                <v-col
                cols="12"
                md="12"
                class="feature-image-section"
                >
                   
                    <div class="feature-image-container">
                        <div class="feature-image"></div>
                        <div class="tagline-and-button-centered">
                            <h1 class="tagline">Share and discover paddle routes on our user driven map</h1>
                            <div class="btn-container">
                                <v-btn
                                class="view-map-btn"
                                @click="goToMapPage()"
                                color="orangehighlight"
                                >
                                    View Map
                                </v-btn>
                                <v-btn
                                    class="sign-up-btn"
                                    @click="goToAuthPage('featuredImage')"
                                    color="orangehighlight"
                                    >
                                    Sign up
                                    <span style="font-size: 12px;font-weight: normal;letter-spacing: normal;text-transform: none;">Receive news and updates</span>
                                </v-btn> 
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid style="padding-top:100px;padding-bottom:80px;text-align:center" class="bg-primary">
            <h2 class="featured-paddle-text" style="margin-bottom:15px;">Tired of paddling the same lake over and over?</h2> 
            <p style="margin-bottom:50px;font-size:20px;">Sign up to be notified as new adventures are added to the map</p>
            <v-btn
                class="view-map-btn"
                style="width:200px;font-size:16px;"
                @click="goToAuthPage('firstSection')"
                color="orangehighlight">
                Sign me up 
            </v-btn>
        </v-container>
        <v-container fluid style="padding-top:80px;padding-bottom:30px;text-align:center;" class="bg-primary-lighten">
            <h2 class="featured-paddle-text" style="color:black;margin-bottom:15px">Already an explorer?</h2> 
            <p style="font-size:20px;">Share your expertise and add your trip to the map. It's easy!</p>
        </v-container>
        <v-container class="panel-container bg-primary-lighten">
            <div class="panel">
                <v-icon
                  dark>
                  mdi-cursor-default-gesture
                </v-icon>
                <!-- <h3>Step 1</h3> -->
                <!-- <div class="draw-tool-image-bg one"></div> -->
                <p>Sign up to use the draw tool and draw your route on the map</p>
            </div>
            <div class="panel">
                <v-icon
                  dark>
                  mdi-form-select
                </v-icon>
                <!-- <h3>Step 2</h3> -->
                <!-- <div class="draw-tool-image-bg two"></div> -->
                <p>Upload images and enter information about the trip</p>
            </div>
            <div class="panel">
                <v-icon
                dark>
                mdi-check-circle-outline
                </v-icon>
                <!-- <h3>Step 3</h3> -->
                <!-- <div class="draw-tool-image-bg three"></div> -->
                <p>Click save, the paddle will be saved to your my paddles page. From there you can share it to the public map.</p>
            </div>
        </v-container>
        <v-container class="bg-primary-lighten" style="text-align:center;padding-bottom:60px;">
            <v-btn
                class="view-map-btn"
                @click="checkAuthenticateBeforeRouting('TopButton')"
                color="orangehighlight"
                >
                Share your trip
            </v-btn>   
        </v-container>
        <v-container class="bg-primary-lighten video-container" >
            <p>This 55 second video shows how to share your route to the map</p>
            <iframe src="https://www.youtube.com/embed/G3_hckzUeWU"></iframe>            
        </v-container>
        <v-container class="bg-primary-lighten" style="text-align:center;padding-bottom:80px;">
            <v-btn
                class="view-map-btn"
                @click="checkAuthenticateBeforeRouting('BottomButton')"
                color="orangehighlight"
                >
                Ok I have a route to share
            </v-btn>   
        </v-container>
        <v-container fluid style="padding-top:80px;padding-bottom:50px;" class="bg-primary">
            <h2 class="featured-paddle-text">Featured Paddles</h2> 
            <v-row class="featured-paddle-tiles">
            
                <v-col
                style="padding:20px;"
                v-for="paddle in featuredPaddles"
                cols="12"
                sm="6"
                md="4"
                >
                    <div class="tile"
                    @click="goToFeaturedPaddle()">
                        <v-img  
                        class="tile-image"  
                        :height="200"
                        cover
                        :src="getPaddleImgUrl(paddle)">
                        </v-img>
                        <div class="tile-text">
                            <h3>{{ paddle.paddleName }}</h3>
                            <p>{{paddle.distance}} miles</p>
                            <p>{{getPaddleTypeNames(paddle.paddleType)}}</p>
                        </div>
                    </div>
                </v-col>
                <v-col
                class="d-none d-sm-flex d-md-none">
                    <div class="tile">
                        <v-img  
                        class="tile-image"  
                        :height="200"
                        cover
                        src="">
                        </v-img>
                    </div>
                </v-col>
                
            </v-row>
        </v-container>
        <v-container fluid style="padding:20px 80px 80px 80px;" class="bg-primary">
            <h2 class="featured-paddle-text" style="margin-bottom:30px;">Why Paddle Routes</h2> 
            <ul>
                <li>
                    Get ideas for your next trip and new places to explore. 
                </li>
                <li>
                    Spend less time trying to decide where to go.
                </li>
                <li>
                    Search for up to date information so the effort is worth it.
                </li>
                <li>
                    A platform for paddle enthusiasts to collaborate and share information. 
                </li>
                <li>
                    Share up to date information about route conditions. Help others not to get caught in the brambles. 
                </li>
                <li>
                    Know what to expect and explore with confidence. Don’t get caught in the brambles.
                </li>
                <li>
                    Track your own trips for personal reference. 
                </li>
            </ul>
        </v-container>
  
       <!-- <v-container style="padding-top:20px;">  
            <v-row>
               <v-col
                cols="12"
                sm="6"
                >
                <p>
                    <h2>Discover</h2>
                    
                    <p>Search the map to find your next outing by kayak, canoe, paddle board or miscillaneous floating device.</p>
                    <p>Filter routes by location, length, paddle type and more!</p>
                </p>
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
                <p>
                    <h2>Know before you go</h2>
                    <p>Don't get stuck in the log jam!</p>
                    <p>Use paddle info, description and comments to find the right route for you.</p>
                </p>
                </v-col> 
                <v-col
                cols="12"
                sm="12">
                <v-btn
                    class="btn-not-featured-image"
                    variant="outlined"
                    color="orangehighlight"
                    depressed
                >
                        Go To Map
                </v-btn>
                </v-col>
            </v-row>
        </v-container>-->
    
        <!--<v-card style="background-color:#ffffff; padding-bottom:50px;padding-top:30px;" flat >
            <v-row class="bg-image-row">     
            </v-row>
        </v-card>-->
        <!-- <v-container class="container-mid">  
            <h2 class="featured-paddle-text" style="color:black;">Share your trip.</h2>
            <v-row>
                
                <v-col
                cols="12"
                sm="6"
                >
                    <div class="draw-tool-image-bg"></div>
                
                </v-col>
                <v-col
                cols="12"
                sm="6"
                >
            
                    <div class="draw-tool-image-blurb"> 
          

                        <p>Draw your route and save it with the draw tool. Upload images, add a description.</p>
                        <p>Contribute it to the public map or keep it private.</p>
                    </div>
                    <v-btn
                        depressed
                        color="orangehighlight"
                        class="btn-not-featured-image"
                        @click="checkAuthenticateBeforeRouting"
                    >
                            Share it
                    </v-btn>
                </v-col>
                
            </v-row>
        </v-container> -->
        <v-container class="container-mid" style="display:none;">  
            <v-row>
                <v-col
                cols="12"
                sm="1" class="d-none d-sm-flex">
                </v-col>
                <v-col
                cols="12"
                sm="10">
                    <v-row> 
                        <v-col 
                        cols="12"
                        sm="4" style="padding:0px;">
                            <div class="phone-logo-icon-div"> 
                                <v-icon
                                    dark
                                    color="info"
                                    style="font-size:50px">
                                    mdi-android
                                </v-icon> 
                                <v-icon
                                    dark
                                    color="info"
                                    style="font-size:50px">
                                    mdi-apple
                                </v-icon>
                            </div>
                            <div class="phone-img-container">
                                <div class="phone-img">

                                </div>
                            </div>
                        </v-col>
                        <v-col 
                        cols="12"
                        sm="8">
                            <h2>Mobile App in Development Android and IOS</h2>
                            <p>Follow routes on your device.</p> 
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                        cols="12"
                        sm="6">
                            <v-text-field
                            v-model="signupEmail"
                            :rules="emailRules"
                            label="E-mail"
                            class="mb-2"
                            required
                            filled
                            color="accent"
                            variant="outlined"
                            density="compact"
                            hide-details
                        ></v-text-field>
                        </v-col>
                        <v-col 
                        cols="12"
                        sm="6">
                            <v-btn
                            color="orangehighlight"
                            style="margin-top:1px;"
                            depressed
                            >
                            Email me when it's ready
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- <h2 style="margin-bottom:10px;">Mobile app</h2> -->
                   
                    <!-- <v-text-field
                        v-model="signupEmail"
                        :rules="emailRules"
                        label="E-mail"
                        class="mb-2"
                        required
                        filled
                        variant="outlined"
                        desity="compact"
                        hide-details
                    ></v-text-field>
                    <v-btn
                        color="orangehighlight"
                        variant="outlined"
                        depressed
                        class="btn-not-featured-image"
                        >
                        Email me when it's ready
                    </v-btn> -->
                </v-col>  
                <v-col
                cols="12"
                sm="1">
                </v-col>             
            </v-row>
        </v-container>
        <!-- <v-container fluid class="bottom-image-section-container">
            <div class="bottom-image"></div>


        </v-container> -->
       
        <v-footer style="background:transparent;">
            <v-row justify="center" no-gutters>
                <v-btn
                    v-for="link in links"
                    :key="link"
                    color="white"
                    variant="text"
                    class="mx-2"
                    rounded="xl"
                >
                    {{ link }}
                </v-btn>
                <v-col class="text-center mt-4" cols="12" style="color:white;margin-top:0px!important; ">
                    <strong>{{ new Date().getFullYear() }} — Paddle Routes</strong>
                </v-col>
            </v-row>
        </v-footer>
    </v-card>
 
  </template>
<script>

import NavBar from '../components/NavBar.vue';
import NODE_API from '../utils/api';
import constants from '../utils/constants'; 
import { Auth } from 'aws-amplify';

export default {
    name: 'LandingPage',
    components: {
      NavBar
    },
    async beforeCreate() {
        let response = await NODE_API.get(`${constants.amazonGatewayUrl}/paddles/sample?num=3`); 
        this.featuredPaddles = response.data.ret; 
    },
    methods: { 
      goToFeaturedPaddle() {
        fbq('trackCustom', 'HomePageFeaturedPaddleClicked', {});
        this.$router.push(`/map/${paddle.paddleNameSlug}`); 
      },
      goToMapPage() {
        fbq('trackCustom', 'HomePageMapButtonClicked', {});
        this.$router.push('/map'); 
      },
      goToAuthPage(btnLocation) {
        fbq('trackCustom', 'HomePageSignUpClicked', {location: btnLocation});
        this.$router.push('/auth'); 
      },
      getPaddleTypeNames(cPaddleType) {
        let namesArray = cPaddleType.map((c) => {
        return constants.cPaddleTypeNames[c]; 
        }); 
        return namesArray.join(', '); 
      },
      getPaddleImgUrl(paddle) {
        let featured = paddle.images.find((p) => p.order == 1); 
        return (featured) ? constants.imgBaseUrl + featured.url : ''; 
      },
      async checkAuthenticateBeforeRouting(btnLocation) {
        fbq('trackCustom', 'HomePageDrawButtonClicked', {location: btnLocation});
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                this.$router.push('/draw');  
            }
        }  
        catch(err) {
            localStorage.setItem('routeAfterAuth', '/draw');
            this.$router.push('/auth'); 
        }
      }
    },
    data: () => ({
        featuredPaddles: [], 
        signupEmail: '',
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],
        links: [
            // 'About',
            // 'Social',
            // 'Contact Us',
        ]
    })
}
</script>

<style lang="scss" scoped>
 .video-container {
    display: flex;
    justify-content: center;
    iframe {
        aspect-ratio: 16 / 9;
        width: 100% !important;
    }
    p {
        margin-bottom:20px; 
    }
 }
 .bg-primary-lighten {
    background-color: #FFFBFF; 
 }
 .panel-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 16px; /* Adds spacing between panels */
      padding: 16px;
    }

    .panel {
      flex: 1;
      min-width: 300px; /* Ensures a good size for smaller screens */
      max-width: 32%; /* Prevents panels from being too wide */
      background-color: #FFFBFF;
      border: 1px solid rgb(var(--v-theme-primary));;
      border-radius: 8px;
      padding: 16px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      .v-icon {
        font-size: 50px;
        margin: 0 auto;
        display: block;
      }
      p {
        margin-top:30px; 
      }
    }

    @media (max-width: 768px) {
      .panel {
        max-width: 100%; /* Stacks panels on smaller screens */
      }
    }
.bottom-image-section-container {
    padding:0px; position:relative; 
    width:100%; 
    padding-bottom:35%; 
    opacity:0.7; 
    min-height:400px; 
}
.bottom-image {
    position:absolute; 
    left:0; right:0; top:0; bottom:0; 
    // background-image: url('../assets/landing_page_img/filler-image.jpg');  
    background-image: url('../assets/landing_page_img/david_canoe.jpeg'); 
    background-size: cover;
    background-position: center center; 
}
.featured-paddle-tiles {
    max-width: 1200px;
    margin:0 auto; 
}
.tile {
    cursor:pointer; 
    background-color:rgb(var(--v-theme-secondary)); 
    .tile-text {
        color:black; 
        min-height:120px; 
        padding-left:20px; 
        h3 {
            margin-bottom:10px; 
        }
    } 
}

h2 {
    font-weight:500; 
}
.btn-not-featured-image {
    // min-width: 50%;  
    margin-top: 25px; 
    display:block; 
}
.v-container {
    padding-left:40px; padding-right:40px;
}
.v-container.feature-image-section-container {
    padding:0px; 
}
.container-mid {
    padding-top:100px; 
    padding-bottom:150px; 
}
.tabs {
    width:auto; 
}
.feature-image-container {
    width:100%; 
    padding-bottom:50%; 
    position:relative;
    min-height:400px; 
}
.feature-image {
    position:absolute; 
    left:0; right:0; top:0; bottom:0; 
    background-image: url('../assets/landing_page_img/winter_group_paddle.jpeg');  //../assets/landing_page_img/david_canoe.jpeg
    background-size: cover;
}
.feature-image-section {
    padding-right:0px; padding-left:0px; padding-bottom:0px; padding-top:0px;
    padding-top:57px; 

    .tagline-and-button-centered {
        position:absolute; 
        z-index:10; 
        left: 50%;
        top: 50%;
        transform:translate(-50%, -250px); 
        width:100%; 
        padding-top: 15px;
        text-align:center; 
    }
    .tagline {
        color:#fffbff; 
        text-align: center;
        margin-bottom: 15px;
        background: rgba(0, 0, 0, 0.2);
        display: inline-block;
        padding: 10px; 
    }
    .btn-container {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width:100%; 
        flex-wrap:wrap; 
        width: 410px;
        max-width: 100%;
        column-gap:10px; 
        justify-content: center; 
        margin-top:100px; 
    }
    .v-btn.view-map-btn {
        width:200px; 
        margin-bottom:10px; 
        font-size:16px; 
    }
    .v-btn.sign-up-btn {
        width: 200px;
        font-size:16px; 
        :deep(.v-btn__content) {
            display:flex; 
            flex-direction:column; 
        }
    }
}
.featured-paddle-text {
    text-align:center; 
    color:#fffbff; 
    text-decoration-color: #fffbff;
}
.tile {
    padding-top: 207px; 
    margin-top: 10px;
    position: relative;
    width: 100%;
    .tile-image {
        position: absolute;
        top: 0px;
        width:100%; 
    }
}


.bg-image-row {
    height:250px; 
    background-image: url('../assets/landing_page_img/winter_group_paddle.jpeg'); 
    background-size:cover;
    background-position:center; 
}
.draw-tool-image-bg {
    background-size:cover;
    background-position:top center; 
    padding-top: 80%;  
    &.one {
        background-image: url('../assets/landing_page_img/draw_one.png'); 
    }   
    &.two {
        background-image: url('../assets/landing_page_img/draw_two.png'); 
        background-position:bottom center; 
    }
    &.three {
        background-image: url('../assets/landing_page_img/draw_three.png'); 
    }
}
.draw-tool-image-blurb {
    p {
        margin-bottom:10px; 
    }
}
.phone-img-container {
    position:relative; 
    width:400px; 
    padding-bottom:40%; 
    .phone-img {
        position:absolute;
        left:0; top:0; bottom:0; right:0; 
        background-image: url('../assets/landing_page_img/phone_image.png'); 
        background-size:contain;
    }
}
.phone-logo-icon-div {
    display:flex; 
    justify-content:center; 
}

.v-footer {
    font-size:12px; 
    background: transparent;
    position: absolute;
    bottom: 0px;
}
a {
    color: rgb(var(--v-theme-orangehighlight)); 
}

@media (max-width: 958px) {
    .feature-image-section {
        .tagline-and-button-centered {
            transform: translate(-50%, -200px);
            line-height: 30px;
            padding: 10px;
        }
    }
 }

@media (max-width: 600px) {
    .v-container:not(.feature-image-section-container) {
        padding-left:20px!important;
        padding-right:20px!important;
    }
    .video-container {
        flex-direction:column; 
    }
}
</style>
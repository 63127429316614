<template>
  <v-layout :class="{'route-draw-tool': true, 'drawer-is-showing':drawerShowHide}">
    <v-dialog v-model="areAuthenticating" fullscreen transition="scroll-x-reverse-transition">
      <v-card>
        <v-icon style="width:40px; font-size:30px; padding:10px;" @click="areAuthenticating = false">mdi-arrow-left</v-icon>
        <login @signInSuccess="allowShowSavePaddle()" simple></login>
      </v-card>
    </v-dialog>
    <v-main class="grey lighten-3">
      <v-toolbar :class="{'draw-tool-btns':true, 'show-small-screen-version':$vuetify.display.width < 670}" :height="$vuetify.display.width < 670 ? 90 :45" dense>   
        <div class="timeline-btns">
          <v-btn 
            :class="{'delete':true, 'my-ctrl':true, 'inactive':nothingToUndo}" small
            :elevation="12"
            @click="clearRoute($event)"
            title="Clear route">
            <v-icon
              dark
              color="info">
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn 
            :class="{'undo':true, 'my-ctrl':true, 'inactive':nothingToUndo}" small 
            :elevation="12"
            @click="undo($event)"
            title="Undo last action">
            <v-icon
              dark
              color="info">
              mdi-undo
            </v-icon>
          </v-btn>
          <v-btn 
            :class="{'redo':true, 'my-ctrl':true, 'inactive':nothingToRedo}" small 
            :elevation="12"
            @click="redo($event)"
            title="Redo last action">
            <v-icon
              dark
              color="info">
              mdi-redo
            </v-icon>
          </v-btn>
        </div>
        <div class="draw-type-btns">
          <v-btn 
            :elevation="12"
            :class="{'draw-straight-line':true,'my-ctrl':true, 'selected':straightLineModeSelected}" small depressed
            @click="toggleStraightLineModeSelected()"
            title="Draw straight line">
            <v-icon
              dark
              color="info">
              mdi-vector-line
            </v-icon>
          </v-btn>
          <v-btn 
            :elevation="12"
            :class="{'draw-snap-to':true,'my-ctrl':true, 'selected':!straightLineModeSelected}" small depressed
            @click="toggleStraightLineModeSelected()"
            title="Snap to waterways">
            <v-icon
              dark
              color="info">
              mdi-vector-radius
            </v-icon>
          </v-btn>
        </div>
    </v-toolbar>
    <v-btn 
      v-if="(isEdit && paddleIsLoaded) || !isEdit"
      :variant="'flat'"
      :class="{'save-route-btn': true, 'inactive': nothingToDelete}" 
      @click="togglePaddleDrawerHideShow(true)"
      title="Add/Edit Route Info">
      {{ saveBtnText }}
      <!-- <template v-slot:prepend>
        <span style="letter-spacing: 0;font-size: 10px;">1/2</span>
      </template> -->
    </v-btn>
      <div id="map" :class="{'map--small-screen':$vuetify.display.width < 670}"></div>  
      <div class="zoom-btns">
        <v-btn 
          small depressed 
          class="zoom-in my-ctrl" 
          @click="mainMap.zoomIn()"
          title="Zoom in">
          <v-icon
            dark
            color="info">
            mdi-plus-thick
          </v-icon>
        </v-btn>
        <v-btn 
          small depressed 
          class="zoom-out my-ctrl" 
          @click="mainMap.zoomOut()"
          title="Zoom out">
          <v-icon
            dark
            color="info">
            mdi-minus-thick
          </v-icon>
        </v-btn>  
      </div>
      <div @click="toggleDistanceUnit()" class="distance">
        <p><span>{{ unitDistance }}</span>&nbsp;{{ distanceUnit }}</p>
        <p class="distance-text">click for {{ otherDistanceUnit }}</p>
      </div>
    </v-main>
  </v-layout>
  <v-overlay class='stop-click-propagation-when-info-showing' :model-value="drawerShowHide" :persistent="true"></v-overlay>
  <aside :class="{'paddle-info': true, 'show': drawerShowHide, 'paddle-info--small-screen':$vuetify.display.width < 670}" >
    <template v-if="!isEdit || (isEdit && paddleIsLoaded)">
      <add-edit-paddle
        :userIsLoggedIn="true"
        :coordinates="lineCoordinates"
        :points="pointCoordinates"
        :paddle="paddle"
        :isEdit="isEdit"
        :milesDistance="milesDistance.toString()"
        @close="togglePaddleDrawerHideShow()"
      ></add-edit-paddle>
    </template>
  </aside>
</template>

<script>
  import {MainMap} from '../utils/mainMap';
  import NavBar from '../components/NavBar.vue';
  import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
  import length from '@turf/length';
  import * as helpers from '@turf/helpers';
  import {nextTick} from 'vue'; 
  import AddEditPaddle from '../pages/AddEditPaddle.vue';
  import Login from '../pages/Login.vue';
  import { Auth } from 'aws-amplify';
  import constants from '../utils/constants'; 
  import NODE_API from '../utils/api';


  export default {
    components: {
      AddEditPaddle,
      NavBar,
      Login
    },
    async created() {
      this.isEdit = this.$route.path.indexOf('edit') > -1; 
    },
    async mounted() {

      try { 
        this.mainMap = new MainMap();

        if (!this.isEdit) {
          await Promise.all([
            this.mainMap.initMap(), 
            this.mainMap.addSearchBox(), 
            this.mainMap.addStraightLineDrawToolClickHandler(this.captureActionCallback, this.updateLineCoordinatesCallback)
          ]); 
          this.addSearchBoxPlaceholder(); 
        } else {
          await Promise.all([
            this.mainMap.initMap(), 
            this.mainMap.addStraightLineDrawToolClickHandler(this.captureActionCallback, this.updateLineCoordinatesCallback)
          ]); 
      
          this.mainMap.map.on('style.load',async () => {
            await this.fetchPaddleToRestore(); 
            this.mainMap.restoreLineAndPoints(this.lineCoordinates, this.pointCoordinates); 
            this.paddleIsLoaded = true; 
            this.togglePaddleDrawerHideShow(); 
          });
        }
        
        this.bindUndoRedoKeyDownEventListeners(); 
      } catch (error) {
        console.error(error);
      } finally {
        this.componentHasMounted = true;
      }
    },
    destroyed() {
      window.removeEventListener('keydown', this.adjustKeyDownMap); 
      window.removeEventListener('keyup', this.adjustKeyDownMap); 
      this.mainMap.removeEventListeners(); 
    }, 
    methods: {
      async fetchPaddleToRestore() {
        let {username, idPaddle} = this.$route.params; 

        // username = true; 
        if (username && idPaddle) {
          this.user = await Auth.currentAuthenticatedUser(); 
          let currentAuthenticatedUsernameSlug = this.user.username.toLowerCase().replace(/ /g,'-');
          let userIsAdmin = this.user.attributes['custom:isAdmin'] == 1; 
          if (currentAuthenticatedUsernameSlug === username || userIsAdmin) { 
            try {
              let response = await NODE_API.get(`${constants.amazonGatewayUrl}/paddle?id=${idPaddle}`); 
              this.paddle = response.data.ret; 
            
              let isLegacy = this.paddle.multi; 
              if (isLegacy) {
                this.paddle.paddleName = this.paddle.name; 
                this.paddle.paddleNameSlug = this.paddle.urlName; 
                delete this.paddle.name; 
                delete this.paddle.urlName; 
              }
              if (Array.isArray(this.paddle.route) && isLegacy) {
                //need to convert to object on save
                this.lineCoordinates = this.paddle.route; 
                this.pointCoordinates = this.paddle.route; 
              } else {
                this.lineCoordinates = this.paddle.route.coordinates; 
                this.pointCoordinates = this.paddle.route.points; 
              }
            } catch (e) {
              console.error(e); 
            }
          } else {
            //not your paddle. you don't have access 
          } 
        }
      }, 
      allowShowSavePaddle() {
        this.areAuthenticating = false; 
        // this.togglePaddleDrawerHideShow(); 
      },
      async togglePaddleDrawerHideShow(fromButton) {
        if (fromButton) {
          fbq('trackCustom', 'DrawToolNextButtonClicked');
        }
   
        try {
          await Auth.currentAuthenticatedUser(); 
        } catch(err) {
          this.areAuthenticating = true; 
          return; 
        }

        this.drawerShowHide = !this.drawerShowHide; 
        
        nextTick(() => {
          this.mainMap.resize(); 
          if (this.drawerShowHide) { //and is desktop view 
            nextTick(() => {
              this.mainMap.fitBoundsToRoute();
            }); 
          }
        }); 
      }, 
      adjustKeyDownMap(evt) { 
        if (evt.key === 'Control' || evt.key === 'Meta' || evt.key === 'Shift') {
          //just track whether cntrl , meta or shift are pressed 
          //the keyup event for z does not fire when meta is held down. so 
          //it can't be tracked correctly. 
          this.keyDownMap[evt.key] = evt.type == 'keydown'; 
        } else {
          if (evt.key === 'z') {
            if ((this.keyDownMap['Control'] || this.keyDownMap['Meta']) && this.keyDownMap['Shift']) {          
              this.redo(evt); 
            } else if ((this.keyDownMap['Control'] || this.keyDownMap['Meta'])) {
              this.undo(evt); 
            }
          }
        
          if (evt.key === 'Backspace') { 
            if(this.throttleBackspacePress == true || this.unitDistance == 0 || this.drawerShowHide === true) {
              return; 
            } else {
              this.throttleBackspacePress = true; 
              this.mainMap.deleteLastSegment(this.lineCoordinates, this.pointCoordinates, this.updateLineCoordinatesCallback, this.captureActionCallback); 
              setTimeout(() => {
                this.throttleBackspacePress = false; 
              },300); 
            }
          }
        }
      }, 
      bindUndoRedoKeyDownEventListeners() {
        window.addEventListener('keydown',this.adjustKeyDownMap);
        window.addEventListener('keyup',this.adjustKeyDownMap); 
      },
      toggleDistanceUnit() {
        if (this.distanceUnit === 'km') {
          this.distanceUnit = 'miles'; 
        } else {
          this.distanceUnit = 'km'; 
        }
      },
      addSearchBoxPlaceholder() {
        const searchBoxInput = document.querySelector('.route-draw-tool .mapboxgl-ctrl input'); 
        searchBoxInput.setAttribute("placeholder","Search for Place or Address"); 
      },
      captureActionCallback(point, addOrRemove, lineSegment = null) {
        if (this.areCurrentlyUndoingAndRedoing) {
           //truncate everything after the current action to 'forget' that fork 
          this.pointStateTimeline = this.pointStateTimeline.slice(0, this.pointStateIndex + 1); 
        }
  
        let timelineAction = {
          point:point, 
          actionType:addOrRemove
        }

        if(lineSegment) {
          timelineAction.segment = lineSegment; 
        }
     
        this.pointStateTimeline.push(timelineAction);
        if (this.areCurrentlyUndoingAndRedoing) {
          this.pointStateIndex = this.pointStateTimeline.length - 1; 
        } 
      },
      updateLineCoordinatesCallback(coordinates, points, opts = {}) {
        this.lineCoordinates = coordinates;
 
        if (opts && opts.pointsPassedAsCoordinateArray) {
          this.pointCoordinates = points; 
        } else {
          this.pointCoordinates = points.map((f) => f.geometry.coordinates); 
        }
      },
      undo(e) {
        e.stopPropagation(); 
        if (this.nothingToUndo) { return; } 
        if (this.pointStateIndex === 'notset') {
          this.pointStateIndex = this.pointStateTimeline.length -1; 
        }
        let lastTimelineAction = this.pointStateTimeline[this.pointStateIndex]; 

        if (lastTimelineAction.actionType === 'add') { 
          this.mainMap.removePointFeatureFromMapAndRedrawLine(this.updateLineCoordinatesCallback); 
        } else if (lastTimelineAction.actionType === 'remove') { 
          let segment = (lastTimelineAction.segment) ? lastTimelineAction.segment : ''; 
          this.mainMap.addPointFeatureToMapAndRedrawLine(lastTimelineAction.point,segment,this.updateLineCoordinatesCallback); 
        } else {
          console.warn('unsupported timeline action type'); 
        }

        if (this.areCurrentlyUndoingAndRedoing === false) {
          this.areCurrentlyUndoingAndRedoing = true; 
        }
        this.pointStateIndex--; 

        if (this.unitDistance == 0) {
          if (lastTimelineAction.actionType === 'remove' && !lastTimelineAction.segment) {
            //just added back the point , add back the first segment 
            let nextUndoTimelineAction = this.pointStateTimeline[this.pointStateIndex]; 
            let {point, segment} = nextUndoTimelineAction; 
            this.mainMap.addPointFeatureToMapAndRedrawLine(point,segment,this.updateLineCoordinatesCallback); 
          }
          //you removed an extra point when you removed the last segment. (the first one drawn)
          //remove the timeline action that recorded adding of the first point 
          this.pointStateIndex--; 
        }
      },
      redo(e) {
        e.stopPropagation(); 
        if (this.nothingToRedo) { return; }
        let lastUndoneTimelineAction = this.pointStateTimeline[this.pointStateIndex + 1]; 
    
        if (lastUndoneTimelineAction.actionType === 'add') {
          let segment = (lastUndoneTimelineAction.segment) ? lastUndoneTimelineAction.segment : ''; 
          //the last undo undid an add, so to redo add the point back . 
          this.mainMap.addPointFeatureToMapAndRedrawLine(lastUndoneTimelineAction.point,segment,this.updateLineCoordinatesCallback); 
        } else if (lastUndoneTimelineAction.actionType === 'remove') {
          this.mainMap.deleteLastSegment(this.lineCoordinates, this.pointCoordinates, this.updateLineCoordinatesCallback, false); 
        } else {
          console.warn('unsupported timeline action type'); 
        }

        this.pointStateIndex++; 

        if (this.areCurrentlyUndoingAndRedoing && this.unitDistance == 0) {
          //add back the first segment too 
          lastUndoneTimelineAction = this.pointStateTimeline[this.pointStateIndex + 1]; 
          if (lastUndoneTimelineAction.actionType === 'add') {
            let segment = (lastUndoneTimelineAction.segment) ? lastUndoneTimelineAction.segment : ''; 
            this.mainMap.addPointFeatureToMapAndRedrawLine(lastUndoneTimelineAction.point,segment,this.updateLineCoordinatesCallback); 
          }
          this.pointStateIndex++; 
        }
      },
      clearRoute(e) {
        e.stopPropagation(); 
        this.pointStateTimeline = []; 
        this.pointStateIndex = 'notset'; 
        this.lineCoordinates = []; 
        this.areCurrentlyUndoingAndRedoing = false; 
        this.mainMap.deleteAllRouteDrawing(); 
      },
      async toggleStraightLineModeSelected() {
        if(this.straightLineModeSelected) {

          this.mainMap.removeDrawToolClickHandler(); 
          this.mainMap.addSnapToWaterwaysClickHandler(this.captureActionCallback, this.updateLineCoordinatesCallback); 
          this.straightLineModeSelected = false; 
        } else {
          
          this.mainMap.removeDrawToolClickHandler(); 
          this.mainMap.addStraightLineDrawToolClickHandler(this.captureActionCallback, this.updateLineCoordinatesCallback);  
          this.straightLineModeSelected = true; 
        }
      }
    },
    computed: {
      saveBtnText() {
        return (this.isEdit) ? 'Next':'Next'; 
      },
      milesDistance() {
        if (this.lineCoordinates.length > 1) {
        let line = helpers.lineString(this.lineCoordinates);
        let distance = length(line, {units: 'miles'});
        return distance.toFixed(2); 
        } else {
          return 0; 
        }
      },
      unitDistance() {
        let distance; 
        if (this.lineCoordinates.length > 1) {
          let line = helpers.lineString(this.lineCoordinates);
          if(this.distanceUnit === 'miles') {
            distance = length(line, {units: 'miles'});
          } else if (this.distanceUnit === 'km') {
            distance = length(line, {units: 'kilometres'});
          } else {
            console.error("distance unit is not valid"); 
          }
        } else {
          distance = 0; 
        }
        return distance.toFixed(2);
      },
      nothingToRedo () {
        return (this.pointStateIndex === (this.pointStateTimeline.length - 1)) || this.pointStateTimeline.length === 0 || this.areCurrentlyUndoingAndRedoing === false;//can only redo if you have undone
      },
      nothingToUndo () {
        return this.pointStateIndex === -1 || this.pointStateTimeline.length === 0; 
      },
      otherDistanceUnit () {
        if (this.distanceUnit === 'miles') {
          return 'km'; 
        } else if (this.distanceUnit === 'km') {
          return 'miles'; 
        }
      },
      nothingToDelete() {
        return this.pointStateTimeline.length === 0 && !this.isEdit; 
      }
    },
    data: () => ({
      mainMap:null,
      componentHasMounted: false,
      mapIsInitialized: false, 
       //tracks user actions for undo and redo buttons 
      pointStateTimeline: [],
      pointStateIndex: 'notset',
      distanceUnit: 'miles', 
      lineCoordinates: [],
      pointCoordinates: [],
      areCurrentlyUndoingAndRedoing: false,
      straightLineModeSelected: true,
      keyDownMap: {}, 
      drawerShowHide: false, 
      areAuthenticating: false, 
      user: {}, 
      paddle: {},
      isEdit: false,
      paddleIsLoaded: false,
      throttleBackspacePress: false 
    }),
    watch: {
    // whenever question changes, this function will run
    // pointStateTimeline(newValue, oldValue) {
    //   console.log('newValue',newValue); 
    // }
  },
  }
</script>

<style lang="scss">
  .route-draw-tool {
    z-index:1!important; 
    &+aside.paddle-info {
      box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
      position: absolute;
      top: 0px;
      z-index:2; 
      bottom: 0px;
      right: 0px;
      transform: translateX(100%);
      transition: transform 0.2s; 
      background-color:white;
      width:40%; 
    }
    &+aside.paddle-info.show {
      transition: transform 0.2s; 
      transform: translateX(0%); 
    }
    &+aside.paddle-info.paddle-info--small-screen {
      width:100%; 
    }
    button.show-route-info-btn {
      position:absolute; 
      right:5px; 
      top:50px; 
      z-index:3; 
      
      .v-btn__content {
      opacity:1 !important; 
      }
    }
    .instruction {
      position:absolute; 
      right:5px; 
      z-index:3; 
      top:92px; 
      font-size:12px; 
      background-color:white; 
      padding:5px; 
    }
    button.save-route-btn {
      position:absolute; 
      right:5px; 
      top:50px; 
      z-index:3; 
      background-color: rgb(var(--v-theme-orangehighlight))!important;
      color: white;
      font-weight: bold;
      transition: background-color 0.3s ease;
      &.inactive {
        background-color:rgba(var(--v-theme-orangehighlight), 0.3)!important; 
      }
    }
    #map {
      position:absolute;
      top:0;bottom:0;right:0;left:0;
      width:100%; 
    }
    button.my-ctrl, button.my-ctrl {
      background-color: #FFFBFF!important;
      min-width:28px!important;
      .v-icon {
        font-size: 20px;   
      }
    }
    button.undo {
      z-index: 1;
      padding:0px 15px!important
    }
    button.redo {
      z-index: 1;
      padding:0px 15px!important
    }
    button.delete {
      z-index: 1; 
      padding: 0px 15px!important; 
    }
    button.draw-straight-line {
      z-index:1; 
      border-right: none; 
      border-radius: 5px 0px 0px 5px; 
    }
    button.draw-snap-to {
      z-index:1; 
      border-left:none; 
      border-radius: 0px 5px 5px 0px; 
    }
    button.draw-straight-line.selected, 
    button.draw-snap-to.selected {
      background-color: rgb(var(--v-theme-accent), 0.75)!important; 
    }
    .distance {
      background: rgba(255,255,255,0.7);
      position:absolute;
      bottom:45px; 
      right:10px;  
      font-size:25px; 
      padding:7px; 
      color: var(--v-info-base); 
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor:pointer; 
      p.distance-text {
        font-size: 12px; 
        font-weight:bold; 
      }
      p { margin-bottom: 0px; line-height:1}
      span {
        font-size: 50px;
        font-weight: bold;
      }
    }
    // .theme--light.v-btn:before{
    //   display:none; 
    // }
    // .v-ripple__container {
    //   opacity: 0 !important;
    // }
    .v-btn.inactive {
      cursor:auto; 
      opacity:0.3; 
    }
    // .v-btn .v-btn__overlay {
    //   display:none; 
    // }
    .mdi {
      font-display: block; 
    }
    .draw-tool-btns.v-toolbar {
      z-index:2; 
      display:flex; 
      margin-top:45px; 
      background-color:rgba(255, 255, 255, 0.7)!important;
      .v-toolbar__content {
        padding-left:5px; 
        width: calc(100% - 400px); 
        display:flex; 
        flex-direction:row; 
        justify-content:space-between; 
        .draw-type-btns {
          margin:auto; 
        }
        .timeline-btns {
          margin-right:auto;  
          .v-btn {
            margin-right:5px; 
            &:last-child {
              margin-right:0px; 
            }
          }
        }
      }       
    }
    .draw-tool-btns.show-small-screen-version {
      .v-toolbar__content {
        padding-top:45px; 
        width:100%; 
        padding-left: 5px;
        padding-right: 5px;
        .draw-type-btns, .timeline-btns {
          margin:0px; 
        }
      }
    }
   
    .zoom-btns {
      position: absolute;
      bottom: 130px;
      right: 10px;
      display: flex;
      flex-direction: column;
      .v-btn:first-child {
        margin-bottom:5px; 
      }
    }
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
      margin-top: 51px;
      padding-right: 75px;
      width: 365px!important;
      input {
        height: 34px; 
      }
    }
    .map--small-screen .mapboxgl-ctrl-top-right {
      width:100%; 
      .mapboxgl-ctrl {
        width: calc(100% - 15px)!important; 
      }
    }
    :focus-visible {
      outline: 2px solid var(--v-theme-accent); 
    }
  }
  .route-draw-tool.drawer-is-showing {
    #map {
      width: 60%; 
    }
    .draw-tool-btns,.my-ctrl {
      display:none; 
    }
    .mapboxgl-control-container {
      display:none; 
    }
  }
  .stop-click-propagation-when-info-showing {
    z-index: 1 !important;
    opacity: 0;
  }
</style>
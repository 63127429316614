import * as helpers from '@turf/helpers';
import buffer from '@turf/buffer';
import distance from '@turf/distance'; 
import pointsWithinPolygon from '@turf/points-within-polygon'; 
import polygonToLine from '@turf/polygon-to-line';
import lineIntersect from '@turf/line-intersect';
import lineSlice from '@turf/line-slice'; 
import polygonSmooth from '@turf/polygon-smooth';  
import nearestPointOnLine from '@turf/nearest-point-on-line';
import union from '@turf/union'; 
import booleanEqual from '@turf/boolean-equal'; 
import simplify from '@turf/simplify';
import booleanIntersects from '@turf/boolean-intersects';
import { isEqual } from 'lodash';
import bbox from '@turf/bbox'; 
import * as visualize from './visualize'; 
const API_KEY = process.env.VUE_APP_API_KEY;
const COLOR_SHORT = '#00AF54'; //< 6 miles
const COLOR_MEDIUM = '#FFC53A'; // >= 6miles <= 11
const COLOR_LONG = '#df323b'; // > 11miles
import constants from '../utils/constants'; 
import { jsPDF } from "jspdf";

export class MainMap {
  constructor() {
    this.map = {};
    this.markers = [];
    this.originalCoords = [-92.289597,34.746483]; // starting position [lng, lat]
    this.currentClickHandler = null; 
    this.snapErrorMargin = 500 //meters (this should depend on zoom level!! ?? )
    this.totalRouteCoords = []; 
    this.prevPointPolygonCoords = []; 
  }

  async initMap(centerCoordinates) {

    mapboxgl.accessToken = API_KEY;
    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: (!centerCoordinates) ? this.originalCoords : centerCoordinates,
      zoom: (!centerCoordinates) ? 1 : 8,
      preserveDrawingBuffer: true
      });
     
    this.map.addControl(new mapboxgl.ScaleControl({unit:'imperial', position: 'top-right'}));   
  }

  async addSearchBox() {
    const searchBox = new MapboxSearchBox({
      types: 'country'
    }); 
    // searchBox.types = 'country, region, postcode, district, city, locality, neighborhood, street'
    searchBox.accessToken = API_KEY;
    this.map.addControl(searchBox); 
    // searchBox.unbindMap(); 
    return searchBox; 
  }

  removeEventListeners() {
    this.map.on('click',null); 
  }

  addMapDragEndListener(handler) {
    this.map.on('dragend',handler);
    this.map.on('zoomend',handler); 
  }

  removeMapDragEndListeners() {
    this.map.on('dragend',null); 
    this.map.on('zoomend',null); 
  }

  restoreLineAndPoints(lineCoordinates, pointCoordinates) {
    this.totalRouteCoords = lineCoordinates; 
    
    let lineFeature = {
      'type': 'Feature',
      'properties': { 'name': 'line', color:'black' },
      'geometry': {
        'type': 'LineString',
        'coordinates': lineCoordinates
      }
    };
    //no line drawn yet so add the source and the layer
    this.map.addSource('route', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
          lineFeature
        ]
      }
    });

    this.map.addLayer({
      'id': 'route-layer',
      'type': 'line',
      'source': 'route',
      'paint': {
      'line-width': 3,
      'line-color': 'black'
      }
    }); 

    let pointFeatures = pointCoordinates.map((p,index) => {
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [p[0], p[1]]
        },
        'properties': {
          'title': index + 1
        }
      }
    }); 

    this.map.addSource('points', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': pointFeatures
      }
    });

    this.map.addLayer({
      'id': 'points-layer',
      'type': 'circle',
      'source': 'points',
      'paint': {
      }
    });
  }

  updateLineAndPoints(lineCoordinates, pointCoordinates) {
    
    this.totalRouteCoords = lineCoordinates; 
    
    let lineFeature = {
      'type': 'Feature',
      'properties': { 'name': 'line', color:'black' },
      'geometry': {
        'type': 'LineString',
        'coordinates': lineCoordinates
      }
    };

    let routeSource = this.map.getSource('route'); 

    routeSource.setData({
      'type': 'FeatureCollection',
      'features': [
        lineFeature
      ]
    }); 

    let pointsSource = this.map.getSource('points'); 

    let pointFeatures = pointCoordinates.map((p,index) => {
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [p[0], p[1]]
        },
        'properties': {
          'title': index + 1
        }
      }
    }); 

    pointsSource.setData({
      "type": "FeatureCollection",
      "features": pointFeatures 
    });

  }

  deleteLastSegment(lineCoordinates, pointCoordinates, updateLineCoordinatesCallback, captureActionCallback) {
   
    let routeSource = this.map.getSource('route'); 
    let routeFeatures = routeSource._data.features;
 
    if (routeFeatures.length > 1) {
      //if line is more than one route feature need to redraw it by popping the 
      //last route segment. Bc undo 'add' (pointStateTimeline) makes the code 
      //pop a route segment. But if we delete like in the else statement below 
      //(that assumes we've loaded continuous line coordinates from the server) 
      //we draw line coordinates as a single route feature. but then if we've previously 
      //added a segment, then deleted (consolidating into one line) then undo, then 
      //we might undo the entire line segment. 
      //bug showed up when loading a preexisting route then - add, add, delete, undo, undo, undo
      this.removePointFeatureFromMapAndRedrawLine(updateLineCoordinatesCallback,captureActionCallback);
    } else {
      //if this has been loaded from the edit the whole route is one feature
      //so can't just pop() the last feature from the map. 
      //figure out segment to remove from the points and lineCoordinates 
      //remove last point 
      let deletedPoint = pointCoordinates.pop(); 

      let deletedSegment; 
      //get segment between last point and previous point 
      let prevPoint = pointCoordinates[pointCoordinates.length - 1]; 
      if (prevPoint) {
        let turfPoint = helpers.point(prevPoint); 
        //THERE IS POTENTIALLY A BUG BELOW IF A POINT IS ON THE LINE MORE THAN ONCE (THO UNLIKELY)
        let indexPrevPointOnLine = lineCoordinates.findIndex((lineCoord) => booleanEqual(helpers.point(lineCoord), turfPoint)); 
        //get all coordinates after the previous point and remove them from the line  
        deletedSegment = lineCoordinates.splice(indexPrevPointOnLine + 1); 
        deletedSegment = [prevPoint, ...deletedSegment]; 
      }

      let prevPointIsLastPoint = pointCoordinates.length == 1; 
      let lastPoint; 
      if (prevPointIsLastPoint) {
        lastPoint = pointCoordinates.pop(); 
        lineCoordinates = []; //point might be duplicated in the line so make sure its cleared out. 
      }

      let opts = { pointsPassedAsCoordinateArray : true }

      updateLineCoordinatesCallback(lineCoordinates,pointCoordinates,opts); 
      this.updateLineAndPoints(lineCoordinates, pointCoordinates); 
    
      if (captureActionCallback) {
    
        let deletedPointFeature = {
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': [deletedPoint[0], deletedPoint[1]]
          }
        }

        let deletedSegmentFeature = {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': deletedSegment
          }
        }
      
        captureActionCallback(deletedPointFeature, 'remove', deletedSegmentFeature); 

        if (lastPoint) {
          let extraPointDeleted = {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [lastPoint[0], lastPoint[1]]
            }
          }

          captureActionCallback(extraPointDeleted, 'remove'); 
        }
      }
    }
  }

  removePointFeatureFromMapAndRedrawLine(updateLineCoordinatesCallback,captureActionCallback = false) {

    let pointsSource = this.map.getSource('points'); 
    let pointFeatures = pointsSource._data.features; 
    //remove most recently added point 
    let deletedPointFeature = pointFeatures.pop(); 

    let routeSource = this.map.getSource('route'); 
    let routeFeatures = routeSource._data.features; //one feature per segement 

    let downToLastPoint = pointFeatures.length === 1; 
    if (downToLastPoint) {
      //remove last point along with last segment 
      pointFeatures.pop(); 
    }
    //update the points
    pointsSource.setData({
      "type": "FeatureCollection",
      "features": pointFeatures 
    });

    let deletedSegmentFeature = routeFeatures.pop(); 
    routeSource.setData({
      "type": "FeatureCollection",
      "features":routeFeatures
    }); 
    //this might give a some duplicate coordinates from end of previous segment with start of next, 
    //but i don't think it affects anything. 
    this.totalRouteCoords = routeFeatures.map((feature) => feature.geometry.coordinates).reduce((val,current) => [...val,...current], []); 

    updateLineCoordinatesCallback(this.totalRouteCoords, pointFeatures); 

    if(captureActionCallback) {
      //dont capture if this called by an 'undo.' but capture if its a remove (backspace keydown)
      captureActionCallback(deletedPointFeature, 'remove', deletedSegmentFeature); 
    }
  }

  addPointFeatureToMapAndRedrawLine(pointFeature, segmentFeature, updateLineCoordinatesCallback) {

    let pointsSource = this.map.getSource('points'); 
    let pointFeatures = pointsSource._data.features;
    
    pointFeatures.push(pointFeature); 
  
    pointsSource.setData({
      "type": "FeatureCollection",
      "features": pointFeatures 
    });
    //add back segment 
    let routeSource = this.map.getSource('route'); 
    let routeFeatures = routeSource._data.features; //one feature per segement 

    if (segmentFeature) {
      routeFeatures.push(segmentFeature); 
    }

    routeSource.setData({
      "type": "FeatureCollection",
      "features": routeFeatures
    });

    let totalRouteCoordinates = routeFeatures.map((feature) => feature.geometry.coordinates).reduce((val,current) => [...val,...current], []); 
    updateLineCoordinatesCallback(totalRouteCoordinates,pointFeatures); 
  }

  removeDrawToolClickHandler() {
    this.map.off('click', this.currentClickHandler);
  }
  
  async addStraightLineDrawToolClickHandler(captureActionCallback,updateLineCoordinatesCallback) {
    this.currentClickHandler = this.getDrawStraightLinesHandler(captureActionCallback,updateLineCoordinatesCallback);
    this.map.on('click', this.currentClickHandler);
  }

  addSnapToWaterwaysClickHandler(captureActionCallback,updateLineCoordinatesCallback) {
    this.currentClickHandler = this.getDrawSnapToWaterwaysHandler(captureActionCallback,updateLineCoordinatesCallback); 
    this.map.on('click',this.currentClickHandler);  
  }

  getDrawStraightLinesHandler(captureActionCallback,updateLineCoordinatesCallback) {
    return (e) => {
      let pointsSource = this.map.getSource('points'); 
      if (typeof pointsSource === 'undefined') {
        this.drawFirstPointAndInitializeLayer(e, captureActionCallback); 
      } else {
        this.addNextPoint(e, pointsSource, captureActionCallback, updateLineCoordinatesCallback); 
      }
    }
  }

  getDrawSnapToWaterwaysHandler(captureActionCallback,updateLineCoordinatesCallback) {
    return (e) => {

      let pointsSource = this.map.getSource('points'); 
      if (typeof pointsSource === 'undefined') {
        this.drawFirstPointAndInitializeLayer(e, captureActionCallback, true); 
      } else {
        this.addNextPoint(e, pointsSource, captureActionCallback, updateLineCoordinatesCallback, true); 
      }
    }
  }
  
  //this takes x y coordinates of a point on the screen. This is different to a point object that 
  //uses longitude and latitude. 
  pointIsOnWater(mapboxScreenPoint) {
    const features = this.map.queryRenderedFeatures(
      [mapboxScreenPoint.x, mapboxScreenPoint.y],
      {layers: ['water']} 
    );
    if (features.length) {
      // this.showFeatureGeometry(features); 
      return true; 
    } else {
      return false; 
    }
  }

  adjustPointToWater(lng,lat,e) { 
    //bottom left and top right points describing a bounding box where the origin is at the top left. 
    const bbox = [
      [e.point.x - 80, e.point.y + 80],
      [e.point.x + 80, e.point.y - 80]
    ]; 
    // Find features intersecting the bounding box.
    const nearbyWaterFeatures = this.map.queryRenderedFeatures(bbox, {
    layers: ['water'] 
    });

    if (nearbyWaterFeatures.length === 0) {
      //no water in bounding box
      return false;
    }

    let thePoint = helpers.point([lng,lat]); 
    let closestPointOnWater = null; 
    
    for(let i =0; i < nearbyWaterFeatures.length; i++) {
      
      let feature = nearbyWaterFeatures[i]; 
      let type = feature.geometry.type; 
      let closestPointInThisFeature = {}; //point and containing polygon
      
      if (type === 'MultiPolygon') {
    
        let polygons = feature.geometry.coordinates.map((coords) => helpers.polygon(coords)); 

        closestPointInThisFeature = polygons.reduce((closest, current) => {
          let lines = polygonToLine(current);
          let nearestPoint = nearestPointOnLine(lines, thePoint);
          if (_.isEmpty(closest) || (distance(thePoint, nearestPoint) < distance(thePoint,closest.point))) {
            return {point:nearestPoint, polygon:current}; 
          } else {
            return {point:closest.point, polygon: closest.polygon} 
          }
        }, {}); 
      }
      if (type === 'Polygon') {
        let polygon = helpers.polygon(feature.geometry.coordinates); 
        let lines = polygonToLine(polygon); 
        closestPointInThisFeature = { point: nearestPointOnLine(lines, thePoint), polygon: polygon}; 
      }

      if (!closestPointOnWater || (distance(thePoint, closestPointInThisFeature.point) < distance(thePoint, closestPointOnWater.point) )) {
        closestPointOnWater = closestPointInThisFeature; 
      }
    }      

    return {
      lng: closestPointOnWater.point.geometry.coordinates[0],
      lat: closestPointOnWater.point.geometry.coordinates[1], 
      polygon:closestPointOnWater.polygon
    }; 
  }

  drawFirstPointAndInitializeLayer(e,captureActionCallback, snapToWater) {
    fbq('trackCustom', 'DrawToolFirstPointDrawn', {});
    let {lng,lat} = e.lngLat

    if (!this.pointIsOnWater(e.point)) {
      this.prevPointPolygonCoords = []; 
    } else {
      this.prevPointPolygonCoords = this.getContainingPolygon([lng, lat]); 
    }
        
    let startPoint = {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [lng, lat]
      },
      'properties': {
        'title': 1
      }
    }; 

    this.map.addSource('points', {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
            startPoint
        ]
      }
    });

    captureActionCallback(startPoint,'add'); 
    //initialize layer
    this.map.addLayer({
      'id': 'points-layer',
      'type': 'circle',
      'source': 'points',
      'paint': {
      }
    });
  }

  addNextPoint(e, pointsSource,captureActionCallback,updateLineCoordinatesCallback, snapToWater) {
    //get current points
    let features = pointsSource._data.features; 
    let numPoints = features.length; 
    //newPointPolygonCoords = false; 

    let {lng,lat} = e.lngLat

    if(snapToWater===true) {
      // if (!this.pointIsOnWater(e.point)) {
        //if either point was not on the water then this segment will not snap to water 
       
        //commented out because of issues with the intersection coordinate pairs 
        //if prev or next point is snapped to the edge of the polygon . 
        //not worth the headache for now. 
        // //point is not on water. snap it to water. 
        // let adjustedPoint = this.adjustPointToWater(lng,lat,e);  
        
        // if (adjustedPoint === false) {
        //   console.error('could not snap point to water. water is too far.'); 
        // } else {
        //   lng = adjustedPoint.lng; 
        //   lat = adjustedPoint.lat; 
        //   //hen we adjust a point it might be right on the edge of a water polygon
        //   //so translating the lat long into a screen point and querying the features for water 
        //   //again can return false.(when we call drawLineBetweenPoints) 
        //   //so we get the polygon here instead of trying to find it 
        //   //again from the lat lng coordinates. 
        //   newPointPolygonCoords = adjustedPoint.polygon.geometry.coordinates; 
        // }
      // }
    }

    let newPoint = {
      'type': 'Feature',
      'geometry': {
        'type': 'Point',
        'coordinates': [lng, lat]
      },
      'properties': {
        'title': numPoints + 1
      }
    };
     
    features.push(newPoint); 
    
    //update with new point 
    pointsSource.setData({
      "type": "FeatureCollection",
      "features": features 
    });

    if (pointsSource._data.features.length > 1) {
      this.drawLineBetweenPoints(features,updateLineCoordinatesCallback, snapToWater, e, captureActionCallback); 
    } else {
      captureActionCallback(newPoint,'add'); 
    }
  }

  getContainingPolygon(lngLatPoint) {

    let mapboxScreenPoint = this.map.project(lngLatPoint); 

    const features = this.map.queryRenderedFeatures(
      [mapboxScreenPoint.x, mapboxScreenPoint.y],
      {layers: ['water']} 
    );
    
    let polygonGeometry = features[0].geometry; 

    let turfPoint = helpers.point(lngLatPoint); 
    let containingPolygonCoords = []; 
    //get the individual polygon that bounds the point 
    if (polygonGeometry.type === 'MultiPolygon') {
      containingPolygonCoords = polygonGeometry.coordinates.find((polygonCoords) => {
        let searchWithin = helpers.polygon(polygonCoords); 
        let ptsWithin = pointsWithinPolygon(turfPoint, searchWithin);
        if (ptsWithin.features.length) {
          return true;
        } else {
          return false; 
        }
      }); 
    } else if (polygonGeometry.type === 'Polygon') {
      containingPolygonCoords = polygonGeometry.coordinates; 
    }
    return containingPolygonCoords;  
  }

  getPointLineString(point, polygonGeometry) {

    if (polygonGeometry.geometry.type === 'LineString') {
   
      return helpers.lineString(polygonGeometry.geometry.coordinates); 

    } else if (polygonGeometry.geometry.type === 'MultiLineString') {

      let dist = null; let closestLineString = null; 
      polygonGeometry.geometry.coordinates.forEach((lineCoords) => {
        let lineString = helpers.lineString(lineCoords);
        let snapped = nearestPointOnLine(lineString, point); 
        if (!dist) {
          dist = snapped.properties.dist; 
          closestLineString = lineString; 
        } else {
          if ( snapped.properties.dist < dist) {
            dist = snapped.properties.dist; 
            closestLineString = lineString; 
          }
        }
      }); 
      
      return closestLineString; 
    }
  }

  buildUpIntersectionPointPairArray(intersectPointsArray, polygonGeometry, array) {
    if (intersectPointsArray.length === 0) return; 
    //we've ordered by distance, so the first point in the array is the first of the pair
    let pair = {
      first: intersectPointsArray[0]
    }; 
    //find the point that goes with this point. its the closest point that is on the same polygon line 
    let firstPointPolygonLine = this.getPointLineString(pair.first, polygonGeometry);  
    let second = {}; 
    let secondIndex = null; 
    for (let i = 1; i < intersectPointsArray.length; i++) {
      let thePoint = intersectPointsArray[i]; 
      let secondPointPolygonLine = this.getPointLineString(thePoint, polygonGeometry); 
      if (_.isEqual(firstPointPolygonLine, secondPointPolygonLine)) {
        second = thePoint; 
        secondIndex = i; 
        break; 
      }
    }
    pair.second = second; 

    array.push(pair); 
    //truncate points array up to and including the second point. the next pair starts from there. 
    let remainingPoints = intersectPointsArray.slice(secondIndex + 1); 
    this.buildUpIntersectionPointPairArray(remainingPoints, polygonGeometry, array); 
  }

  flipSlicedCoordsIfNeeded(pointFrom, pointTo, coordsBetween) {
    //depending on which side of the polygon the line was sliced from , we might have 
    //to flip the coordinates so that the beginning of the sliced line starts from 
    //the point from, and the end ends at the point to  
    let coordsBetweenStart = coordsBetween[0]; 
    let slicedSegmentStart = helpers.point(coordsBetweenStart); 

    if (distance(pointFrom, slicedSegmentStart) > distance(pointTo, slicedSegmentStart)) {
      return coordsBetween.reverse(); 
    } else {
      return coordsBetween; 
    }
  }

  adjustLineToPolygon(polygonCoords, newPoint, prevPoint) {
    //find intersecting points. these are where straight line 
    //between points exits or reenters the water boundry
    //intersecting points come in pairs of exit and reentry. 
    //assume we have already adjusted the new point to lie on the water. 
    
    //scenarios - exits on one side, enters on the same side. 
    //- exists on one side, enters on the other side. 
    //(river would have to bend over on itself in a spiral?) v.unlikely?? 
    let line = helpers.lineString([prevPoint,newPoint]); 
    //smooth the polygon -we might follow its edge so we want it to be as non-jaggedy as possible
    let polygon = helpers.polygon(polygonCoords); 

    polygon = polygonSmooth(polygon, {iterations:5}); 
    // let options = {tolerance: 0.0005, highQuality: true, mutate:false};
    // polygon = simplify(polygon, options); 
  
    let polygonGeojson = polygon.features[0].geometry; 
    
    // visualize.showFeatureGeometry(this.map, polygonGeojson); 
  
    let intersectPoints = lineIntersect(line,polygonGeojson); 
    
    if (intersectPoints.features.length) {
      //we have intersections! we need to adjust the line 
      let adjustedLine = []; 

      let intersectPointsGeojson = intersectPoints.features.map((feature) => feature.geometry); 
      let prevPointGeojson = helpers.point(prevPoint).geometry; 
      //sort in asc distance from prev point (point from) 
      const compareDist = (a, b) => {
        if (distance(prevPointGeojson, a) < distance(prevPointGeojson,b)) {
          return -1; 
        } else {
          return 1; 
        }
      }
      // visualize.showPoints(this.map,intersectPoints.features); 

      intersectPointsGeojson.sort(compareDist); 
      //get first pair of points that leaves the water then reenters the water
      let polygonMultiLine = polygonToLine(polygonGeojson); 

      let pointPairArray = []; 
      this.buildUpIntersectionPointPairArray(intersectPointsGeojson, polygonMultiLine, pointPairArray); 
      // console.log('the prev point',prevPoint); 
      // console.log('new point',newPoint); 
      // console.log('the point pair array',pointPairArray); 
      //we have our ordered pairs of intersection points! now build up our adjusted line 
      pointPairArray.forEach((pair) => {
        try {
          let lineStringToAdjustRouteTo = this.getPointLineString(pair.first, polygonMultiLine); //doesn't matter whether its the first or second, they share a linestring
          //visualize.lineStringToAdjustRouteTo(this.map, lineString); 
          let sliced = lineSlice(pair.first, pair.second, lineStringToAdjustRouteTo); 
          let slicedCoords = sliced.geometry.coordinates; 
          slicedCoords = this.flipSlicedCoordsIfNeeded(pair.first,pair.second,slicedCoords); 
          adjustedLine = [...adjustedLine,...slicedCoords];
        } catch (e) {
          console.error({error: e, coordinatePair: pair}); 
        } 
      }); 

      adjustedLine.push(newPoint); 
    
      return adjustedLine; 
    } else {
      return [newPoint]; //no prev point because that was included as the previous new point 
    }
  }

  featureContainsPolygon(feature,polygon) {
    let geometry = feature.geometry; 
    let containsPolygon = false; 
    if (geometry.type === 'MultiPolygon') {
      geometry.coordinates.forEach((coords) => {
        if( _.isEqual(coords,polygon.geometry.coordinates)) {
          containsPolygon = true; 
        }
      }); 
    } else {
      if ( _.isEqual(geometry.coordinates, polygon.geometry.coordinates)) {
        containsPolygon = true; 
      }
    }
    return containsPolygon; 
  }

  getPolygonsAreContiguous(polygonOne,polygonTwo) {
    let combined = union(polygonOne,polygonTwo); 
    if (combined.geometry.type === 'MultiPolygon') {
      return false; 
    } else {
      return combined; 
    }
  }

  buildUpContiguousPolygons(polygonFeaturesBetween,startPolygon,endPolygon) {
    let finalPolygon = null;  
    
    for (let i = 0; i < polygonFeaturesBetween.length; i++) {

      if (finalPolygon) {
        break; 
      }

      let feature = polygonFeaturesBetween[i]; 
      let geometry = feature.geometry; 
      
      if (geometry.type === 'MultiPolygon') {
        
        //each coordinates array is a polygon 
        for (let j = 0; j < geometry.coordinates.length; j++) {
          if (finalPolygon) {
            break; 
          }
          let coords = geometry.coordinates[j]; 
          let polygon = helpers.polygon(coords); 
          //if is continguous returns a new polygon which is the unioned polygon 
          let areContiguous = this.getPolygonsAreContiguous(startPolygon,polygon); 
          if (areContiguous) {
            let newStartPolygon = areContiguous; 
            let isContiguousWithEnd = this.getPolygonsAreContiguous(newStartPolygon,endPolygon); 
            if (isContiguousWithEnd) {
              finalPolygon = isContiguousWithEnd; 
            } else {
              //not contiguous with end polygon. need to keep looking 
              //by filtering out the feature we assume that only one polygon per feature will be 
              //contiguous. I think this is a safe assumption unless we can have two branches of a river 
              //in different polygons?? 
              let newPolygonFeaturesBetween = polygonFeaturesBetween.filter((f) => !_.isEqual(f,feature)); 
              finalPolygon = this.buildUpContiguousPolygons(newPolygonFeaturesBetween,newStartPolygon,endPolygon); 
            }
          }
        }
        //end looping through multipolygon coordinates array.
        //if still haven't found a contiguous polygon, check the next feature.  

      } else if (geometry.type === 'Polygon') {

        let coords = geometry.coordinates; 
        let polygon = helpers.polygon(coords); 
        let areContiguous = this.getPolygonsAreContiguous(startPolygon,polygon); 
        if (areContiguous) {
          let newStartPolygon = areContiguous; 
          let isContiguousWithEnd = this.getPolygonsAreContiguous(newStartPolygon,endPolygon); 
          if (isContiguousWithEnd) {
            finalPolygon = isContiguousWithEnd; 
          } else {
            let newPolygonFeaturesBetween = polygonFeaturesBetween.filter((f) => !_.isEqual(f,feature)); 
            finalPolygon = this.buildUpContiguousPolygons(newPolygonFeaturesBetween,newStartPolygon,endPolygon); 
          }
        }
      }
    } //end looping through features. If haven't formed a contiguous polygon that joins the start point 
    //and end point polygons, then there isn't one. 

    if (finalPolygon) {
      return finalPolygon; 
    } else {
      return false; 
    }
  } 

  findAndCombineContiguousPolygonsBetweenPrevAndNewPoint(polygonOne,polygonTwo, prevPoint, newPoint) {
    //get water features in bounding box 
    let mapboxScreenPointOne = this.map.project(prevPoint); 
    let mapboxScreenPointTwo = this.map.project(newPoint); 
    //bottom left and top right points describing a bounding box, 
    //where the origin is at the top left. 
    let bottomLeftPoint = [ Math.min(mapboxScreenPointOne.x, mapboxScreenPointTwo.x), Math.max(mapboxScreenPointOne.y, mapboxScreenPointTwo.y) ]; 
    let topRightPoint = [ Math.max(mapboxScreenPointOne.x, mapboxScreenPointTwo.x), Math.min(mapboxScreenPointOne.y, mapboxScreenPointTwo.y) ]; 

    const allWaterPolygonsBetweenPrevAndNewPoint = this.map.queryRenderedFeatures(
      [bottomLeftPoint, topRightPoint],
      {layers: ['water']} 
    );
    //disregard those features which contain first and last point. 
    //we already have polygonOne and polygonTwo. we need the polygons in between 
    let onlyPolygonFeaturesBetween = allWaterPolygonsBetweenPrevAndNewPoint.filter((feature) => {
      return !this.featureContainsPolygon(feature, polygonOne) && !this.featureContainsPolygon(feature, polygonTwo); 
    }); 

    let startPolygon = polygonOne; 
    let endPolygon = polygonTwo; 

    let combined = this.buildUpContiguousPolygons(onlyPolygonFeaturesBetween, startPolygon, endPolygon); 
  
    return combined; 
  }

  drawLineBetweenPoints(features,updateLineCoordinatesCallback, snapToWater, e, captureActionCallback) {
    
    let newRouteCoords = []; //newly added route coordinates. will be tacked onto end of total line
    let segmentCoords = []; //just the coordinates for this segment 
    let prevPointCoords = features[features.length - 2].geometry.coordinates; 
    if (features.length ===2 ) {
      //this is the first line drawn. add the first coordinate. 
      //after this we don't add previous point coordinates since they are
      //included as the new point of the previous segment drawn. 
      newRouteCoords.push(prevPointCoords); 
    }

    let newPointFeature = features[features.length - 1]; 
    
    let newPointCoords = newPointFeature.geometry.coordinates;
      
    let newPointPolygonCoords;    
    if (this.pointIsOnWater(e.point)) {
      newPointPolygonCoords = this.getContainingPolygon(newPointCoords); 
    } else {
      newPointPolygonCoords = []; 
    }
    
    let drawStraightLineSegment = !snapToWater; 
    let lineSegmentInvalid = false; 
    if (snapToWater===true && newPointPolygonCoords.length && this.prevPointPolygonCoords.length) {
      //both points should be on the water in order to snap to water 
      //find contiguous polygons to adjust the line to 
      let adjustedLineCoordinates = []; 
      if (_.isEqual(newPointPolygonCoords,this.prevPointPolygonCoords)) { 
        //the prev point and new point are in the same polygon 
        adjustedLineCoordinates = this.adjustLineToPolygon(newPointPolygonCoords, newPointCoords, prevPointCoords); 
      } else {
        let polygonOne = helpers.polygon(this.prevPointPolygonCoords); 
        let polygonTwo = helpers.polygon(newPointPolygonCoords);

        let combinedPolygon; 
        let polygonsAreContiguous = this.getPolygonsAreContiguous(polygonOne,polygonTwo);
        if (polygonsAreContiguous) {
          combinedPolygon = polygonsAreContiguous; 
        } else {
          combinedPolygon = this.findAndCombineContiguousPolygonsBetweenPrevAndNewPoint(polygonOne,polygonTwo, prevPointCoords, newPointCoords); 
        }

        if (combinedPolygon === false) {
          drawStraightLineSegment = true; 
          lineSegmentInvalid = true; 
          console.error('there is no water path between prev point and new point'); 
        } else {
          // visualize.showFeatureGeometry(this.map, combinedPolygon.geometry); 
          adjustedLineCoordinates = this.adjustLineToPolygon(combinedPolygon.geometry.coordinates, newPointCoords, prevPointCoords); 
        }
      }
      if (adjustedLineCoordinates.length) {
        //add the snapped to line coordinates to line 
        newRouteCoords = [...newRouteCoords, ...adjustedLineCoordinates]; 
        segmentCoords = [prevPointCoords, ...adjustedLineCoordinates]; 
      }
    } else {
      drawStraightLineSegment = true; 

      if (snapToWater===true) {
        lineSegmentInvalid = true; 
      }
    }
      
    if (drawStraightLineSegment===true) {
      newRouteCoords = [...newRouteCoords, newPointCoords]; 
      segmentCoords = [prevPointCoords, newPointCoords]; 
    }
    //save this since we might not be able to get it using queryRenderedFeatures when the 
    //next point is drawn. it might be off the map. 
    this.prevPointPolygonCoords = newPointPolygonCoords;
     
    this.totalRouteCoords = [...this.totalRouteCoords, ...newRouteCoords]; 

    let segmentColor = (lineSegmentInvalid) ? 'red': 'black'; 
 
    let routeSource = this.map.getSource('route'); 
    
    let segmentFeature; 
    //no line drawn yet so add the source and the layer
    if (typeof routeSource === 'undefined') {

      segmentFeature = {
        'type': 'Feature',
        'properties': { 'name': 'segment_1', color:segmentColor },
        'geometry': {
          'type': 'LineString',
          'coordinates': segmentCoords
        }
      };

      //no line drawn yet so add the source and the layer
      this.map.addSource('route', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
              segmentFeature
            ]
          }
      });

      this.map.addLayer({
        'id': 'route-layer',
        'type': 'line',
        'source': 'route',
        'paint': {
        'line-width': 3,
        'line-color': ['get', 'color']
        }
      });

    } else {

      let segments = routeSource._data.features; 

      segmentFeature = {
        'type': 'Feature',
        'properties': { 'name': `segment_${segments.length + 1}`, 'color':segmentColor},
        'geometry': {
          'type': 'LineString',
          'coordinates': segmentCoords
        }
      };
       //update existing source
       routeSource.setData({
        "type": "FeatureCollection",
        "features": [ ...segments, segmentFeature ] 
      });
    }

    captureActionCallback(newPointFeature, 'add', segmentFeature); 
    //update line distance
    updateLineCoordinatesCallback(this.totalRouteCoords, features); 
  }

  deleteAllRouteDrawing() {
    if (this.map.getLayer('route-layer')) {
      this.map.removeLayer('route-layer');
    } 
    if (this.map.getSource('route')) {
      this.map.removeSource('route'); 
    }
    if (this.map.getLayer('points-layer')) {
      this.map.removeLayer('points-layer'); 
    } 
    if (this.map.getSource('points')) {
      this.map.removeSource('points'); 
    }
  
    visualize.deleteAllSourceAndLayers(this.map); 
    this.totalRouteCoords = []; 
  
  }
  resize() {
    this.map.resize(); 
  }

  fitBoundsToRoute(routeCoords) {
    let route; 
    if (routeCoords) {
      route = routeCoords; 
    } else {
      route = this.totalRouteCoords; 
    }
    let lineString = helpers.lineString(route); 
    let theBbox = bbox(lineString); 
    let bottomLeft = [theBbox[0], theBbox[1]]; 
    let topRight = [theBbox[2], theBbox[3]]; 
    this.map.fitBounds([bottomLeft, topRight], { padding: {top:85, bottom:20, left:20, right:30}, animate: false }); 

  }

  centerOnLocation(locationCoords, zoomLevel = 8) {

    let opts = {
      center:(locationCoords) ? [locationCoords.lng, locationCoords.lat] : this.originalCoords,
      speed: 2, 
      zoom: zoomLevel
    }; 

    this.map.flyTo(opts);
  }

  toggleLineArrowLayer(trueOrFalse) {

    if (trueOrFalse) {
      const url = '/images/arrow.png'
      this.map.loadImage(url, (err, image) => { 
        if (err) { return; }
        if (!this.map.hasImage('arrow')) {
          this.map.addImage('arrow', image);
        }
        this.map.addLayer({
          'id': 'arrow-layer',
          'type': 'symbol',
          'source': 'routes',
          'layout': {
            'symbol-placement': 'line',
            'symbol-spacing': 1,
            'icon-allow-overlap': true,
            // 'icon-ignore-placement': true,
            'icon-image': 'arrow',
            'icon-size': 0.2,
            'visibility': 'visible'
          }
        });
      });
    } else {
      this.map.removeLayer('arrow-layer');
    }
  }

  getPaddleTypeNames(cPaddleType) {
    let namesArray = cPaddleType.map((c) => {
      return constants.cPaddleTypeNames[c]; 
    }); 
    return namesArray.join(', '); 
  }

  addMapMarkers(paddles, clickCallback, mouseEnterCallback, mouseLeaveCallback, attachMoreInfoClickHandler,removeMoreInfoClickHandler) {

    //remove previous markers 
    if (this.markers.length) {
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].remove();
      }
    } 

    this.markers = paddles.map((paddle) => {
      const el = document.createElement('div');
      el.className = 'marker';

      let markerColor = this.getPaddleColor(paddle, true);
      markerColor = 'marker--' + markerColor
      el.classList.add(markerColor);

      let markerPopupHtml = ''; 
      if (paddle.images && paddle.images.length) {
        let featuredImagePath = paddle.images[0].url;

        let nLastSlash = featuredImagePath.lastIndexOf('/'); 
        let thumbnailPath = featuredImagePath.substring(0, nLastSlash + 1) + '400w-' + featuredImagePath.substring(nLastSlash + 1); 

        let fullPath = constants.imgResizedBaseUrl + thumbnailPath;

        markerPopupHtml += `<div class='marker-popup-image' style='background-image:url(${fullPath})'></div>`; 
      }

      markerPopupHtml += `<h1>${paddle.paddleName}</h1>`; 
      markerPopupHtml += `<p>${paddle.distance} miles</p>`; 
      markerPopupHtml += (paddle.paddleType) ? `<p>${this.getPaddleTypeNames(paddle.paddleType)}</p>` : ''; 
      markerPopupHtml += `<span class='more-info-text more-info-text--${paddle._id}'>More Info</span>`; 
   
      const popup = new mapboxgl.Popup({ offset: 25, closeButton:false }).setHTML(
          markerPopupHtml
        );
      
      let elem; 
      popup.on('open', () => {
        elem = attachMoreInfoClickHandler(paddle._id, popup); 
      });

      popup.on('close', () => { 
        removeMoreInfoClickHandler(paddle._id, popup); 
      }); 

      const marker = new mapboxgl.Marker(el, {anchor:'bottom-left'})
        .setLngLat([paddle.location[0], paddle.location[1]])
        .setPopup(popup) 
        .addTo(this.map);

      let markerElem = marker.getElement();

      //when click marker 
      markerElem.addEventListener('click', (evt) => {
          clickCallback(paddle._id);
       });

      //when hover over maker 
      markerElem.addEventListener('mouseenter', () => {
        mouseEnterCallback(paddle._id); 
      });
      //when mouse leave 
      markerElem.addEventListener('mouseleave', () => {
        mouseLeaveCallback(paddle._id) 
      }); 

      markerElem.dataset.paddle_id = paddle._id;

      return marker;
    });
  }

  hideShowMarkers(filteredPaddleIds) {

    this.markers.forEach((m) => {
      let markerElem = m.getElement();

      if (filteredPaddleIds.includes(markerElem.dataset.paddle_id)) {
        markerElem.style.visibility = "visible";
      } else {
        markerElem.style.visibility = "hidden";
      }
    });
  }

  showMoreInfoButton(idPaddle) {
    let theMarker;
    this.markers.forEach((m) => {
      let markerElem = m.getElement();
      if ( markerElem.dataset.paddle_id == idPaddle ) {
        theMarker = markerElem;
      }
    });

    let icon = document.createElement('i');
    icon.classList.add('v-icon', 'v-icon__more-info', 'notranslate','mdi','mdi-information-outline','theme--dark','primary--text');
    icon.dataset.paddle_id = idPaddle;

    theMarker.after(icon);
  }

  getMapMarkers() {
    return this.markers;
  }

  getPaddleColor(thePaddle,strVal = false) {
    let color;
    let distance = Number(thePaddle.distance);
    if (distance < 6 ) {
      if (strVal) {
        color = 'green';
      } else {
        color = COLOR_SHORT;
      }
    } else if ( distance <= 11 ) {
      if (strVal) {
        color = 'yellow';
      } else {
        color = COLOR_MEDIUM;
      }
    } else {
      if (strVal) {
        color = 'red';
      } else {
        color = COLOR_LONG;
      }
    }
    return color;
  }

  drawPaddleRoutes(idPaddlesToShow, paddleData, callback) {

    if (idPaddlesToShow.length === 0) {
      this.removeAllRoutes();
      return; 
    }

    //if source already exists, push to features array and update source data
    let source = this.map.getSource('routes');

    if (typeof source !== 'undefined') {

      let features = idPaddlesToShow.map((id) => {
        let thePaddle = paddleData.find((e) => e._id === id);
        let color = 'black'; //this.getPaddleColor(thePaddle);

        return {
          'type': 'Feature',
          'properties': { 'name': thePaddle.paddleName, color: color, id: id },
          'geometry': {
            'type': 'LineString',
            'coordinates': thePaddle.route.coordinates
          }
        }
      })
      //update the source
      source.setData({
        "type": "FeatureCollection",
        "features": features
      });

    } else {
      //otherwise add the source with the route
      let thePaddle = paddleData.find((e) => e._id === idPaddlesToShow[0]);

      let color = 'black';

      let feature = {
        'type': 'Feature',
        'properties': { 'name': thePaddle.paddleName, 'color':color, 'id':idPaddlesToShow[0]},
        'geometry': {
          'type': 'LineString',
          'coordinates': thePaddle.route.coordinates
        }
      };
      this.map.addSource('routes', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [
               feature
             ]
          }
      });

      this.map.addLayer({
        'id': 'layer',
        'type': 'line',
        'source': 'routes',
        'paint': {
        'line-width': 3,
        // 'line-color': ['get', 'color']
        }
      });
      // this.map.on('click', 'layer', (e) => { 
      //   callback(e.features[0].properties.id);
      // });

      this.map.on('mouseenter', 'layer', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      });
      this.map.on('mouseleave', 'layer', () => {
        this.map.getCanvas().style.cursor = ''
      })
    }
  }

  removeAllRoutes(idArrayRoutesToKeep) {
    let routeSource = this.map.getSource('routes'); 
    let currentFeatures = routeSource._data.features; 
    let newFeatures = currentFeatures.filter((feature) => idArrayRoutesToKeep.includes(feature.properties.id)); 
      
    routeSource.setData({
      "type": "FeatureCollection",
      "features": newFeatures
    });
  }

  zoomIn() {
    this.map.zoomIn();
  }

  zoomOut() {
    this.map.zoomOut();
  }

  generatePdf(paddle) {
    const mapImg = this.map.getCanvas().toDataURL('image/jpeg');
    var doc = new jsPDF("p", "px", "a4");
    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();
    // console.log('the width', width); 
    // console.log('the height',height); 
    doc.addImage(mapImg, 'JPEG', 0, 0, width,height);
    // const distanceString = paddle.distance.toString() + ' miles'; 
    // doc.text(distanceString, 10, 10);
    const docName = paddle.paddleNameSlug + '.pdf'; 
    doc.save(docName);
  }
}

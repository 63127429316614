<template>
  <v-overlay :model-value="overlay" :persistent="true"></v-overlay>
  <confirm-dialog 
    v-if="confirmationDialog.show"
    v-bind="confirmationDialog"
    @close="closeAndResetConfirmationDialog"
    @checkmarkAnimationStarted="adjustDialogProps_AnimationStart">
  </confirm-dialog>
  <v-main>
    <div v-if="!myPaddlesLoaded" class="spinner-container">
      <v-progress-circular
        indeterminate
        color="accent"
        :size="60"
      ></v-progress-circular>
    </div>
    <v-alert 
      v-if="showErrorMessage"
      color="error"
      variant="outlined"
      :text="errorMsg"
    ></v-alert>
    <v-container v-if="myPaddlesLoaded" :class="{'my-paddles-not-mobile':$vuetify.display.smAndUp}">
      <v-tabs
        v-if="userIsAdmin"
        v-model="tab"
        color="accent"
        style="margin-bottom:15px;"
      >
        <v-tab value="mytrails">My Routes</v-tab>
        <v-tab value="pending"> 
          <v-badge
            v-if="pendingPaddles.length"
            color="error"
            inline
            :content="pendingPaddles.length"
          ></v-badge>
          Pending
        </v-tab>
        <v-tab value="mainmaptrails">Main Map Routes</v-tab>
      </v-tabs>
      <v-window v-model="tab">
        <v-window-item value="mytrails"> 
          <section style="display:flex;max-width:800px;">
            <v-icon :color="'orangehighlight'">mdi-information-outline</v-icon>
            <span class="shared-to-map-hint">
              {{ sharedToMapHint }}
            </span>  
          </section>  
          <v-card v-for="paddle in myPaddles" :key="paddle._id" :variant="'outlined'" style="max-width:800px;margin-bottom:10px;">
            <v-row no-gutters style="padding:10px;">
              <v-col cols="12" sm="2">
                <div style="width:100%; padding-top:65%;position:relative;">
                  <div class="action-item-container d-sm-none">
                    <v-btn 
                      style="min-width:0px;"
                      :color="'error'"
                      :variant="'outlined'"
                      @click="deleteTrail(paddle)"
                      class="delete-trail-btn"
                      title="Delete Route">
                      <v-icon>
                        mdi-delete
                      </v-icon>
                      <!-- Delete -->
                    </v-btn>
                    <v-btn 
                      style="min-width:0px;padding:10px;"
                      :color="'accent'"
                      @click="editTrail(paddle)"
                      class="edit-trail-btn"
                      title="Edit Route">
                      <!-- <v-icon>
                        mdi-pencil
                      </v-icon> -->
                      Edit
                    </v-btn>
                    <v-btn 
                      v-if="!paddle.sharedToMap"
                      style="min-width:0px;"
                      :color="'orangehighlight'"
                      @click="shareTrail(paddle)"
                      class="share-trail-btn"
                      title="Share Route To Public Map">
                      Share to map
                    </v-btn>
                    <span v-else>
                      <v-icon color="orangehighlight">
                        mdi-check-circle-outline
                      </v-icon>
                      <span style="font-size:10px;width: 60px;line-height:10px;display:inline">Shared!</span>
                    </span>
                  </div>
                  <div :style="'background-image:' + getFeaturedImgSrc(paddle.images) + ';background-position:center center; background-size:cover;background-repeat:no-repeat;position:absolute;top:0;bottom:0;left:0;right:0;'"></div>
                </div>
              </v-col>
              <v-col cols="12" sm="10" style="display:flex; flex-direction:row;">
                <v-card-title class="paddle-title">
                  {{ paddle.paddleName }}
                </v-card-title>
                <span class="action-item-container d-none d-sm-flex">
                  <v-btn 
                    style="min-width:0px;"
                    :color="'error'"
                    :variant="'outlined'"
                    @click="deleteTrail(paddle)"
                    class="delete-trail-btn"
                    density="compact"
                    title="Delete Route">
                    <v-icon>
                      mdi-delete
                    </v-icon>
                    <!-- Delete -->
                  </v-btn>
                  <v-btn 
                    style="min-width:0px;"
                    :color="'accent'"
                    @click="editTrail(paddle)"
                    class="edit-trail-btn"
                    density="compact"
                    title="Edit Route">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-btn>
                  <v-btn 
                    v-if="!paddle.sharedToMap"
                    style="min-width:0px;"
                    :color="'orangehighlight'"
                    @click="shareTrail(paddle)"
                    class="share-trail-btn"
                    density="compact"
                    title="Share Route To Public Map">
                    <v-icon>
                      mdi-share
                    </v-icon>
                    Share to map
                  </v-btn>
                  <span v-else>
                    <v-icon color="orangehighlight">
                      mdi-check-circle-outline
                    </v-icon>
                    <span style="font-size: 10px;width:60px;line-height:10px;display:inline;">Shared!</span>
                  </span>
                </span>
                <span class="action-item-container-bottom d-none d-sm-flex">
                  
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item value="pending"> 
          <section style="display:flex;max-width:800px;">
            <v-icon :color="'orangehighlight'">mdi-information-outline</v-icon>
            <span class="shared-to-map-hint">
              Routes appear here for review when they have been 'shared to map' by whoever created them. Editing from here directly edits the user's version (they will be able to see the changes from their 'My Routes' page). Publishing creates a copy of the user's version and shows it on the map. 
            </span>  
          </section>
          <v-card v-for="paddle in pendingPaddles" :key="paddle._id" :variant="'outlined'" style="max-width:800px;margin-bottom:10px;">
            <v-row no-gutters style="padding:10px;">
              <v-col cols="12" sm="2">
                <div style="width:100%; padding-top:65%;position:relative;">
                  <div class="action-item-container d-sm-none">
                    <v-btn 
                      style="min-width:0px;padding:10px;"
                      :color="'accent'"
                      @click="editTrail(paddle)"
                      class="edit-trail-btn"
                      title="Edit Route">
                      <!-- <v-icon>
                        mdi-pencil
                      </v-icon> -->
                      Edit
                    </v-btn>
                    <v-btn 
                      style="min-width:0px;"
                      :color="'orangehighlight'"
                      @click="copyTrailToMainMap(paddle)"
                      class="share-trail-btn">
                      Publish
                    </v-btn>    
                  </div>
                  <div :style="'background-image:' + getFeaturedImgSrc(paddle.images) + ';background-position:center center; background-size:cover;background-repeat:no-repeat;position:absolute;top:0;bottom:0;left:0;right:0;'"></div>
                </div>
              </v-col>
              <v-col cols="12" sm="10" style="display:flex; flex-direction:row;">
                <v-card-title class="paddle-title">
                  {{ paddle.paddleName }}
                </v-card-title>
                <span class="action-item-container d-none d-sm-flex">
                  <v-btn 
                    style="min-width:0px;"
                    :color="'accent'"
                    @click="editTrail(paddle)"
                    class="edit-trail-btn"
                    density="compact"
                    title="Edit Route">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-btn> 
                  <v-btn 
                    style="min-width:0px;"
                    :color="'orangehighlight'"
                    density="compact"
                    @click="copyTrailToMainMap(paddle)"
                    class="share-trail-btn">
                      Publish
                  </v-btn>     
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
        <v-window-item value="mainmaptrails"> 
          <section style="display:flex;max-width:800px;">
            <v-icon :color="'orangehighlight'">mdi-information-outline</v-icon>
            <span class="shared-to-map-hint">
              Routes that show on the public map are listed here. If removed, behind the scenes the public copy will be deleted. It will no longer show on the map and it will go back to Pending status as the user version of the route. If edit is allowed from here it will directly modify the public version, not the user version. 
            </span>  
          </section>
          <v-card v-for="paddle in mainMapPaddles" :key="paddle._id" :variant="'outlined'" style="max-width:800px;margin-bottom:10px;">
            <v-row no-gutters style="padding:10px;">
              <v-col cols="12" sm="2">
                <div style="width:100%; padding-top:65%;position:relative;">
                  <div class="action-item-container d-sm-none">
                    <v-btn 
                      style="min-width:0px;margin-right:10px;"
                      @click="unpublishTrail(paddle)"
                      :color="'orangehighlight'"
                      class="unshare-trail-btn"
                      density="compact"
                      title="Unpublish Route">
                      <v-icon>
                        mdi-undo
                      </v-icon>
                      Remove
                    </v-btn> 
                    <v-btn 
                      style="min-width:0px;padding:10px; margin-right:0px;"
                      :color="'accent'"
                      @click="editTrail(paddle)"
                      disabled
                      class="edit-trail-btn"
                      title="Edit Route">
                      <!-- <v-icon>
                        mdi-pencil
                      </v-icon> -->
                      Edit
                    </v-btn> 
                  </div>
                  <div :style="'background-image:' + getFeaturedImgSrc(paddle.images) + ';background-position:center center; background-size:cover;background-repeat:no-repeat;position:absolute;top:0;bottom:0;left:0;right:0;'"></div>
                </div>
              </v-col>
              <v-col cols="12" sm="10" style="display:flex; flex-direction:row;">
                <v-card-title class="paddle-title">
                  {{ paddle.paddleName }}
                </v-card-title>
                <span class="action-item-container d-none d-sm-flex">
                  <v-btn 
                    style="min-width:0px;margin-right:10px;"
                    @click="unpublishTrail(paddle)"
                    :color="'orangehighlight'"
                    class="unshare-trail-btn"
                    density="compact"
                    title="Unpublish Route">
                    <v-icon>
                      mdi-undo
                    </v-icon>
                    Remove
                  </v-btn> 
                  <v-btn 
                    style="min-width:0px; margin-right:0px;"
                    :color="'accent'"
                    @click="editTrail(paddle)"
                    class="edit-trail-btn"
                    disabled
                    density="compact"
                    title="Edit Route">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-btn>     
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
    </v-container>
  </v-main>
</template>

<script>
  import NODE_API from '../utils/api';
  import constants from '../utils/constants'; 
  import AddEditPaddle from '../pages/AddEditPaddle.vue';
  import NavBar from '../components/NavBar.vue';
  import { Auth } from 'aws-amplify';
  import ConfirmDialog from '../components/ConfirmDialog.vue';
  import { nextTick } from 'vue'

  export default {
    name: 'MyPaddlesView',
    components: {
      AddEditPaddle,
      NavBar,
      ConfirmDialog
    },
    props: {
      userId: String
    },
    async created() {
      this.user = await Auth.currentAuthenticatedUser(); 
      this.userIsAdmin = (this.user.attributes['custom:isAdmin'] == 1) ? true : false; 
      
      this.usernameSlug = this.user.username.toLowerCase().replace(/ /g,'-');     
      try {
        let response = await NODE_API.get(`${constants.amazonGatewayUrl}/paddles?username=${this.usernameSlug}&admin=${this.userIsAdmin}`); 
   
        this.myPaddles = response.data.ret.userPaddles;
        this.pendingPaddles = response.data.ret.pendingPaddles; 
        this.mainMapPaddles = response.data.ret.mainMapPaddles; 

        this.myPaddlesLoaded = true; 
      } catch (e) {
        console.error(e); 
      }
    },
    mounted() {
      console.log(this.$vuetify.display); 
    },
    methods: { 
      async unpublishTrail(paddle) {
        this.errorMsg = ''; 
        this.showErrorMessage = false; 

        const body = {
          paddleId: paddle._id, 
          username: this.usernameSlug, 
          admin: this.userIsAdmin,
          propertiesToUpdate: { published: false }
        }; 
        
        const unpublishTrail = async () => {
          try {
            const response = await NODE_API.put(`${constants.amazonGatewayUrl}/paddle/unpublish`, body);

            if (response.data.success) {
              this.pendingPaddles = response.data.ret.pendingPaddles; 
              this.mainMapPaddles = response.data.ret.mainMapPaddles; 
            } else {
              console.error('something went wrong unpublishing the paddle'); 
              throw(e); 
            }
          } catch (e) {
            console.error('something went wrong', e); 
            throw(e); 
          }
        }; 
  
        this.confirmationDialog.callback = unpublishTrail; 
        this.confirmationDialog.show = true; 
        this.confirmationDialog.message = `Remove <strong>${paddle.paddleName}</strong> from the public map? It will need to be reapproved by an admin.`;
        this.confirmationDialog.confirmBtnText = 'Unpublish'; 
        this.confirmationDialog.cancelBtnText = 'Cancel'; 
        this.confirmationDialog.confirmBtnColor = 'orangehighlight'; 
        this.confirmationDialog.checkmarkAnimation = true;  
        this.confirmationDialog.errorMsg = 'Paddle could not be unpublished. Please contact the developer'; 
      },
      async copyTrailToMainMap(paddle) {
        this.errorMsg = ''; 
        this.showErrorMessage = false; 

        const body = {
          paddleId: paddle._id, 
          username: this.usernameSlug, 
          admin: this.userIsAdmin,
          propertiesToUpdate: { published: true }
        }; 
        
        const copyToMainMap = async () => {
          try {
            const response = await NODE_API.put(`${constants.amazonGatewayUrl}/paddle/publishToMap`, body);

            if (response.data.success) {
              this.pendingPaddles = response.data.ret.pendingPaddles; 
              this.mainMapPaddles = response.data.ret.mainMapPaddles; 
            } else {
              console.error('something went wrong sharing paddle to map'); 
              throw(e); 
            }
          } catch (e) {
            console.error('something went wrong', e); 
            throw(e); 
          }
        }; 
  
        this.confirmationDialog.callback = copyToMainMap; 
        this.confirmationDialog.show = true; 
        this.confirmationDialog.message = `Publish route <strong>${paddle.paddleName}</strong> to the public map?`;
        this.confirmationDialog.confirmBtnText = 'Publish'; 
        this.confirmationDialog.cancelBtnText = 'Cancel'; 
        this.confirmationDialog.confirmBtnColor = 'orangehighlight'; 
        this.confirmationDialog.checkmarkAnimation = true;  
        this.confirmationDialog.errorMsg = 'Paddle could not be published. Please contact the developer'; 
      },
      async closeAndResetConfirmationDialog(errorMsg) {
        this.confirmationDialog.show = false; 
        await nextTick(); 
        this.confirmationDialog = { show: false, 
                                    message: '', 
                                    callback: null,
                                    confirmBtnText: '',
                                    cancelBtnText: '', 
                                    stayUp: false, 
                                    checkmarkAnimation: false, 
                                    showCloseButton: false }

          if (errorMsg) {
            this.showErrorMessage = true; 
            this.errorMsg = errorMsg; 
          }
                                 
      },
      adjustDialogProps_AnimationStart(props) {
        this.confirmationDialog.message = ''; 
        this.confirmationDialog.showButtons = false; 
      },
      async shareTrail(paddle) {
        this.errorMsg = ''; 
        this.showErrorMessage = false; 

        const body = {
          paddleId: paddle._id,
          propertiesToUpdate: { sharedToMap: true },
          username: this.usernameSlug, 
          admin: this.userIsAdmin,
        }; 
        
        const flagTrailAsSharedToMap = async () => {
          try {
            const response = await NODE_API.put(`${constants.amazonGatewayUrl}/paddle/shareToMap`, body);

            if (response.data.success) {
              this.myPaddles = response.data.ret.userPaddles; 
              this.pendingPaddles = response.data.ret.pendingPaddles; 
              this.mainMapPaddles = response.data.ret.mainMapPaddles; 
             
            } else {
              console.error('something went wrong sharing paddle to map'); 
              throw(e); 
            }
          } catch (e) {
            console.error('something went wrong', e); 
            throw(e); 
          }
        }; 
  
        this.confirmationDialog.callback = flagTrailAsSharedToMap; 
        this.confirmationDialog.show = true; 
        this.confirmationDialog.message = `Share the route <strong>${paddle.paddleName}</strong> to the public map?`;
        this.confirmationDialog.confirmBtnText = 'Share'; 
        this.confirmationDialog.cancelBtnText = 'Cancel'; 
        this.confirmationDialog.confirmBtnColor = 'orangehighlight'; 
        this.confirmationDialog.checkmarkAnimation = true;  
        this.confirmationDialog.errorMsg = 'Paddle could not be shared. Please contact administrator'; 
      }, 
      async deleteTrail(paddle) {
       
        const body = {
          paddleId: paddle._id, 
          usernameSlug: this.usernameSlug, 
          admin:this.userIsAdmin,
          published: (paddle.published) ? true : false
        }
        const deleteTrail = async () => {
          this.overlay = true; 
          const response = await NODE_API.delete(`${constants.amazonGatewayUrl}/paddle`, {
            data: body
          });

          if (response.data.success) {
            this.myPaddles = response.data.ret.userPaddles; 
            this.pendingPaddles = response.data.ret.pendingPaddles; 
            this.mainMapPaddles = response.data.ret.mainMapPaddles; 
          } else {
            console.error('something went wrong with paddle delete'); 
          }
          this.overlay = false; 
        }; 

        this.confirmationDialog.callback = deleteTrail; 
        this.confirmationDialog.show = true; 
        this.confirmationDialog.message = `Are you sure you want to delete the route? <strong>${paddle.paddleName}</strong>`;
        
        let additionalMessage = ''; 
        if (paddle.sharedToMap) {
          additionalMessage = `<br><br>(This will delete it from My Paddles but not from the main map)`; 
        }

        this.confirmationDialog.message += additionalMessage; 
        
        this.confirmationDialog.confirmBtnText = 'Delete'; 
        this.confirmationDialog.cancelBtnText = 'Cancel'; 
        this.confirmationDialog.confirmBtnColor = 'error'; 
      },
      editTrail(paddle) {
        // let considerMeAnOwner = this.user.username.toLowerCase().replace(/ /g,'-'); 
        let paddleOwnerSlug = paddle.usernameSlug; 
        let route = `edit/${paddleOwnerSlug}/${paddle.paddleNameSlug}/${paddle._id}`; 
        this.$router.push({path:route}); 
      },
      getFeaturedImgSrc(images) {
        if(!images) return; 
        let theFeatured = images.find((image) => image.order == 1); 
        if (theFeatured) {
          let nLastSlash = theFeatured.url.lastIndexOf('/'); 
          let thumbnailPath = theFeatured.url.substring(0, nLastSlash + 1) + '400w-' + theFeatured.url.substring(nLastSlash + 1); 

          return 'url(' + constants.imgResizedBaseUrl + thumbnailPath + ')';
        } else {
          return ''; 
        } 
      }
    },
    computed: {
    
    },
    data: () => ({
      tab: 'mypaddles',
      showErrorMessage: '',
      errorMsg: '',
      myPaddles: [],
      pendingPaddles: [],
      mainMapPaddles: [],
      myPaddlesLoaded: false,
      paddleToEdit: {},
      overlay: false,
      userIsAdmin: false, 
      usernameSlug: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          class: 'name-col'
        },
        { text: 'Date Uploaded', value: 'dtUploaded', class: 'dtuploaded-col'},
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      showEditPaddle: false,
      user: {},
      confirmationDialog: {
        show: false, 
        message: '', 
        callback: null,
        confirmBtnText: '',
        cancelBtnText: '', 
        stayUp: false, 
        checkmarkAnimation: false, 
        showCloseButton: false
      }, 
      sharedToMapHint: "When shared to map your route will be reviewed and added to the public map. Once added, you may edit or delete your version of the route, but the public version will be unaffected."
    }),
  }
</script>

<style lang="scss" scoped>
  .action-item-container {
    min-width: 0px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 2;
  }

  .action-item-container-bottom {
    @extend .action-item-container; 
    bottom:5px; 
    top: unset; 
  }

  .edit-trail-btn.v-btn {
    margin-right:10px;
    color:white!important;
  }
  .delete-trail-btn.v-btn {
    margin-right:10px; 
  }

  .edit-trail-btn, .delete-trail-btn {
    padding-inline:5px; 
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  .paddle-title {
    white-space: break-spaces;
    line-height: 1.5rem !important;
    flex-grow:1; 
    max-width:100%; 
    padding:0px; 
    padding-top:10px; 
    font-weight:normal; 
  }

  .my-paddles-not-mobile {
    .paddle-title {
      padding-top:0px; 
      padding-left:10px; 
    }
  }
  
  .shared-to-map-hint {
    font-size:12px; 
    line-height:14px; 
    display:block; 
    text-align:left; 
    padding-left:5px; 
    padding-bottom:5px; 
    color:gray; 
    margin-bottom:10px; 
  }
</style>

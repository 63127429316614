<template>
  <v-app>
    <v-dialog :content-class="'user-settings-page'" scrollable persistent style="max-width:600px;" :modelValue="showUserPreferencesDialog">
      <v-card>
          <v-card-text style="height: 500px;">
            <user-settings :isInDialog="true" @close="showUserPreferencesDialog = false;"></user-settings>
          </v-card-text>
      </v-card>
    </v-dialog>
    <nav-bar @refreshPage="forceRouterReload"></nav-bar>
    <router-view :key="refreshRouterKey"></router-view>
  </v-app>
</template>

<script>

import NavBar from './components/NavBar.vue';
import { Auth } from 'aws-amplify';
import NODE_API from './utils/api';
import constants from './utils/constants'; 
import UserSettings from './pages/UserSettings.vue'; 

export default {
    name: 'App',
    components: {
      NavBar,
      UserSettings 
    },
    async beforeCreate() {
      //app is refreshing. happens after login, but also if the user refreshes the page/logs out. 
      //if this was a login prompted by an attempt to get to the draw tool, route the user 
      //to the draw tool. not perfect as the user might get to the auth page by clicking 
      //the draw tool menu item, but then navigate around, then go to aut manually - 
      //then they will still be directed to the draw tool even though the intention was past.
      let routeAfterAuth = localStorage.getItem('routeAfterAuth');
      localStorage.removeItem('routeAfterAuth'); 

      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          if (routeAfterAuth) {
            //is logged in with directions to go to a particular page right after login
            this.$router.push(routeAfterAuth); 
          }
          //check if logged in for the first time 
          try {
            let userMetadata = await NODE_API.get(`${constants.amazonGatewayUrl}/userMetadata?username=${user.username}`);
   
            if (!userMetadata.data.ret) {
              this.showUserPreferencesDialog = true; 
              return; 
            }
          } catch (e) {
            console.error(e); 
          }
          //is logged in 
          if(this.$route.path == '/' || this.$route.name === 'loginView' || this.$route.matched.length == 0) {
            this.$router.push('/map'); 
          }         
        }
      }  
      catch(err) {
          //is not logged in 
          if(this.$route.path == '/' || this.$route.matched.length == 0) {
            this.$router.push('about'); 
          } else {
            if (this.$route.name === 'myPaddlesView' || this.$route.name === 'routeDrawTool' || this.$route.name === 'editPaddle' || this.$route.name === 'userSettings') {
              this.$router.push('/auth'); 
            }
          }
      }
    },
    methods: {
      forceRouterReload() {
        this.refreshRouterKey++; 
      }
    },
    data: () => ({
      refreshRouterKey:0, 
      showUserPreferencesDialog: false 
    })
}
</script>

<style lang="scss">
  .user-settings-page {
    overflow:scroll;
    background-color:white; 
    .v-main {
      padding-top:0px!important; 
    }
  }
</style>